<script setup>
import { onBeforeMount, ref } from 'vue';
const displayCookiesBanner = ref(false);
onBeforeMount(() => {
  if (!getCookie('cookiesAccepted')) {
    displayCookiesBanner.value = true;
  }
});

/**
 * Sets a cookie with the specified name, value, and duration.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {number} durationInDays - The duration of the cookie in days. Default is 1 day.
 */
const setCookie = (name, value, durationInDays = 1) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + (durationInDays * 24 * 60 * 60 * 1000)); // 1 day (24 hours)
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
}

/**
 * Retrieves the value of a cookie by its name.
 * @param {string} name - The name of the cookie.
 * @returns {string|null} - The value of the cookie, or null if the cookie is not found.
 */
const getCookie = (name) => {
  var nameEQ = name + "=";
  var cookiesArray = document.cookie.split(';');
  for (var i = 0; i < cookiesArray.length; i++) {
    var cookie = cookiesArray[i];
    while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) == 0) return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
}

/**
 * Function to accept cookies.
 * Sets the 'cookiesAccepted' cookie to true and hides the cookies banner.
 */
const acceptCookies = () => {
  setCookie('cookiesAccepted', true, 30);
  displayCookiesBanner.value = false;
}

/**
 * Function to decline cookies.
 * It sets the 'cookiesAccepted' cookie to false and hides the cookies banner.
 */
const declineCookies = () => {
  setCookie('cookiesAccepted', false, 30);
  displayCookiesBanner.value = false;
}
</script>

<template>
  <div v-if="displayCookiesBanner" class="cookies-policy-wrapper">
    <h4>Manage Cookies</h4>
    <p class="message">
      We and our third-party partners may use cookies and similar technologies to personalize our website functionalities and measure website usage and performance. 
      For more information about our privacy practices, please visit our <RouterLink to="/privacy-policy" class="link">Privacy Policy</RouterLink>
    </p>
    <div class="button-wrapper">
      <button class="btn secondary-btn" @click="declineCookies">Decline</button>
      <button class="btn primary-btn" @click="acceptCookies">Accept</button>
    </div>
  </div>
</template>

<style scoped>
.cookies-policy-wrapper {
  background-color: #f5f5f5;
  padding: 2rem;
  border-radius: 5px;
  margin: 2rem 0;

  display: flex;
  flex-direction: column;
  gap: 12px;
  bottom: 0;
  position: fixed;
  z-index: 800;
}
.message {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
.link {
  font-size: 1.25rem;
  text-decoration: none;
}
.button-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
</style>