<template>
    <section class="hero-banner">
        <div class="wrapper">
            <div class="hero-content">
                <h1>Welcome to Polsinelli Online Solutions</h1>
                <div class="main-info">
                    <p>
                        Polsinelli Online Solutions is a scalable online subscription platform designed to assist 
                        home-based care employers of all sizes with staying compliant with the myriad of federal, 
                        state and municipal laws applicable to the industry. 
                        Polsinelli Online Solutions equips employers with a comprehensive set of 
                        customizable forms, tools and resources at the federal, state, and local 
                        levels necessary to comply with the complexities of your industry's diverse legal landscape.
                    </p>
                </div>
                <!-- <div class="btn-wrapper disflex a_center j_start f_wrap"> -->
                    
                    <RouterLink v-if="!loggedIn" to="/solutions/request-demo" class="btn primary-btn" >
                        Request a Demo
                    </RouterLink>
                    <button v-if="loggedIn" href="#" class="btn white-btn" @click.prevent="showAssistanceModal = true">
                        Posh Support
                    </button>
                <!-- </div> -->

            </div>
        </div>
        <a href="#scroll-down">
            <div id="scroll-down" class="down-arrow">
                <img src="/assets/icons/down-arrow-black.svg" />
            </div>
        </a>
    </section>

    <!-- <teleport to="body">
        <transition name="modal">
            <RequestDemoModal v-show="showDemoModal" @close="showDemoModal = false"/>
        </transition>
    </teleport> -->
    <teleport to="body">
        <transition name="modal">
            <RequestAssistanceModal v-show="showAssistanceModal" @close="showAssistanceModal = false"/>
        </transition>
    </teleport>
</template>

<script setup>
import { ref } from 'vue';
import RequestDemoModal from "../subscriber/RequestDemoModal.vue";
import RequestAssistanceModal from "../subscriber/RequestAssistanceModal.vue";
import { RouterLink } from 'vue-router';

const showDemoModal = ref(false);
const showAssistanceModal = ref(false);
const loggedIn = ref(JSON.parse(localStorage.getItem('user')));
</script>

<style lang="scss" scoped>
.hero-banner {
    background-image: url('/assets/images/hero/hero-banner-v1.jpg');
    transition: 0.3s;
    height: calc(100vh - 64px);
    position: relative;

    .hero-content {
        margin: 30px 0;

        @media (min-width: 768px) {
            max-width: 600px;

            @media (min-width: 992px) {
                margin: 80px 0;

                @media (min-width: 1200px) {
                    max-width: 800px;

                    @media (min-width: 1400px) {
                        max-width: 800px;
                        margin: 60px 0;
                    }
                }
            }
        }

        .main-info {
            margin-bottom: 20px;
            text-shadow: 0 1px 5px rgba(255, 255, 255, 0.4);
        }

        .btn-wrapper {
            margin-top: 40px;

            a.btn {
                margin-bottom: 10px;
                width: 100%;

                @media (min-width: 768px) {
                    width: auto;
                    margin-right: 20px;
                }
            }
        }
    }
    
    .down-arrow {
        position: absolute;
        left: 50%;
        bottom: 3rem;
        transform: translateX(-50%);
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #9f3037;
        padding: 5px;

        img {
            max-height: 100%;
            width: auto;
            filter: invert(1);
        }

        -webkit-animation: bounce 1s infinite; 
	    animation: bounce 4s 3s infinite;
    }
}

// MEDIA QUERIES
@media (min-width: 992px) {
    .hero-banner {
        height: calc(100vh - 64px);
    }
}

@media (min-width: 1441px) {
    .hero-banner {
        height: calc(100vh - 82px);
    }
}

@media (min-width: 1640px) {
    .hero-banner {
        height: calc(100vh - 82px);
    }
}

@media (max-height: 767px) {
    section.hero-banner {
        padding-top: 20px;
    }

    section.hero-banner .wrapper {
        padding: 0 20px;
    }

    section.hero-banner .hero-content {
        margin: 20px 0;
    }

    section.hero-banner .hero-content h1 {
        font-size: 1.75rem;
    }
}


// ANIMATIONS
.modal-enter-from, .modal-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.modal-enter-active, .modal-leave-active {
    transition: all 0.3s ease;
}

@-webkit-keyframes bounce {
	 0% { bottom: 3rem; }
	 10%   { bottom: 3.5rem; }
	 20%   { bottom: 3rem; }
	 30%   { bottom: 3.5rem; }
	 40%   { bottom: 3rem; }
	 100% { bottom: 3rem; }
}

@keyframes bounce {
    0% { bottom: 3rem; }
    10%   { bottom: 3.5rem; }
    20%   { bottom: 3rem; }
    30%   { bottom: 3.5rem; }
    40%   { bottom: 3rem; }
    100% { bottom: 3rem; }
}
</style>