import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'


const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        // If navigating to the HRDocuments page, scroll to the top
        if (
          to.name === 'HrDocumentsFormsTemplates' ||
          to.name === 'StateSpecificEmploymentResourcesTemplates' ||
          to.name === 'Covid19Resources' ||
          to.name === 'NewsAndInsights' ||
          to.name === 'Events' ||
          to.name === 'MergersAndAcquisitions' ||
          to.name === 'About' ||
          to.name === 'BusinessFormation' || 
          to.name === 'EmployeeRetentionCredit' || 
          to.name === 'LicenseEnrollmentDatabaseMaintenance' || 
          to.name === 'PolicyProcedureManualsLicensingGuides' || 
          to.name === 'HIPAADataPrivacyAndSecurity' ||
          to.name === 'TrainingRecruitmentRetention' ||
          to.name === 'Contact' ||
          to.name === 'RequestDemo'
        ) {
          // return { x: 0, y: 0 };
          return { top: 0 };
        }
        // If navigating back to the HRDocuments page, return to the saved position
        else if (savedPosition) {
          return savedPosition;
        }
        // Otherwise, scroll to the top of the new page
        else {
          // return { x: 0, y: 0 };
          return { top: 0 };
        }
      }
});

router.beforeEach((to, from, next) => {
    // This code checks whether the user is logged in and redirects them to the login page if they are not.
    /* const publicPages = [
      'Login', 
      'Register','PageNotFound', 
      'HomeLayout', 
      'Homepage', 
      'About', 
      'OurServices', 
      'Covid19Resources', 
      'TrainingRecruitmentRetention', 
      'OurResources', 
      'PolsinelliServices', 
      'HealthCareConsulting', 
      'Contact', 
      'ResetRequest',
      'ResetPassword',
      'BusinessFormation',
      'EmployeeRetentionCredit',
      'LicenseEnrollmentDatabaseMaintenance',
      'PolicyProcedureManualsLicensingGuides',
      'HrDocumentsFormsTemplates'
    ]; */
  
    const publicPages = [
      'Login',
      'Register',
      'PageNotFound',
      'ResetRequest',
      'ResetPassword',
      'Homepage',
      'Contact',
      'About',
      'BusinessFormation',
      'EmployeeRetentionCredit',
      'LicenseEnrollmentDatabaseMaintenance',
      'PolicyProcedureManualsLicensingGuides',
      'TrainingRecruitmentRetention',
      'RequestDemo',
      'UnsubscribeSuccessPage',
      'PrivacyPolicy',
    ]

    const authRequired = !publicPages.includes(to.name);
    const loggedIn = localStorage.getItem('user');
    const SelectedOrganization = localStorage.getItem('SelectedOrganization');
    // trying to access a restricted page + not logged in
    // redirect to login pageexport default router

  /*   if(publicPages.includes(to.name) && loggedIn && SelectedOrganization === null && (from.name == 'Login')){
      next(false);
    } */

    
    if (authRequired && !loggedIn ) {
        next('/login');
    } else {
        next();
    }
})

export default router