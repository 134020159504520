import Homepage from "../pages/Homepage.vue";
/* import About from "../pages/About.vue";
import Login from "../pages/Login.vue"; */
//import Register from "../pages/Register.vue";
/* import ResetRequest from "../pages/ResetRequest.vue";
import ResetPassword from "../pages/ResetPassword.vue";
import SuperAdmin from "../pages/admin/SuperAdmin";
import ContentAdmin from "../pages/admin/ContentAdmin";
import MediaFilesDashboard from "../pages/admin/MediaFilesDashboard.vue";
import DocumentFilesDashboard from "../pages/admin/DocumentFilesDashboard";
import ProprietaryFilesDashboard from "../pages/admin/ProprietaryFilesDashboard";
import Organisations from "../pages/admin/Organisations";
import OrganisationGroups from "../pages/admin/OrganisationGroups"
import Modules from "../pages/admin/Modules";
import HRDocumentsFormsTemplates from "../pages/HRDocumentsFormsTemplates.vue";
import Events from "../pages/Events.vue";
import Contact from "../pages/Contact.vue";
import PolsinelliServices from "../pages/PolsinelliServices.vue";
import HealthCareConsulting from "../pages/HealthCareConsulting.vue";
import Covid19Resources from "../pages/Covid19Resources.vue";
import TrainingRecruitmentRetention from "../pages/TrainingRecruitmentRetention.vue";
import NewsAndInsights from '../pages/NewsAndInsights.vue';
import StateSpecificEmploymentResourcesTemplates from "../pages/StateSpecificEmploymentResourcesTemplates.vue";
import HIPAADataPrivacyAndSecurity from '../pages/HIPAADataPrivacyAndSecurity.vue'
import MyProprietaryDocuments from "../pages/MyProprietaryDocuments.vue";
import BusinessFormation from "../pages/BusinessFormation.vue";
import EmployeeRetentionCredit from "../pages/EmployeeRetentionCredit.vue";
import LicenseEnrollmentDatabaseMaintenance from "../pages/LicenseEnrollmentDatabaseMaintenance.vue";
import PolicyProcedureManualsLicensingGuides from "../pages/PolicyProcedureManualsLicensingGuides.vue";
import FranchiseContent from "../components/subscriber-flow/Imanage-components/FranchiseContent.vue";
import MergersAndAcquisitions from "../pages/MergersAndAcquisitions.vue";
import ContentViewer from "../components/subscriber-flow/Imanage-components/ContentViewer.vue" */

const routes = [
    {
        path: "/",
        component: Homepage,
        name: "Homepage",
        alias: ["/solutions/Home"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/';
            } else {
                return true;
            }
        },
        children: [
            {
              path: '/about',
              component: ()=>import(/* webpackChunkName: "about" */"../pages/About.vue"),
              name: "About",
              alias: ["/solutions/about-us"],
              beforeEnter: () => {
                const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
                const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
                if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                    localStorage.clear();
                    return '/about';
                } else {
                    return true;
                }
            },
            },
            {
              path: '/contact',
              component: ()=>import(/* webpackChunkName: "contact" */"../pages/Contact.vue"),
              name: "Contact",
              alias: ["/solutions/contact-us"],
              beforeEnter: () => {
                const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
                const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
                if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                    return '/login';
                } else {
                    return true;
                }
            },
            },
            {
                path: "/login",
                component: ()=>import(/*webpackChunkName: "login" */"../pages/Login.vue"), 
                name: "Login",
                alias: ["/solutions/LoginRequiredPage.action?checkMaster=true"],
            },
            {
                path: "/polsinelli-services",
                component: ()=>import(/*webpackChunkName: "polsinelli-services" */"../pages/PolsinelliServices.vue"),
                name: "PolsinelliServices",
                alias: ["/solutions/polsinelli-services"],
                beforeEnter: () => {
                    const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
                    const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
                    if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                        return '/login';
                    } else {
                        return true;
                    }
                },
            },
            {
                path: "/health-care-consulting",
                component: ()=>import(/*webpackChunkName: "health-care-consulting" */"../pages/HealthCareConsulting.vue"),
                name: "HealthCareConsulting",
                alias: ["/solutions/health-care-consulting"],
                beforeEnter: () => {
                    const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
                    const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
                    if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                        return '/login';
                    } else {
                        return true;
                    }
                },
            },
            {
                path:"/solutions/request-demo",
                component: ()=>import(/*webpackChunkName: "license-enrollment-database-maintenance" */"../pages/RequestDemo.vue"),
                name: "RequestDemo",
                beforeEnter: () => {
                    const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
                    const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
                    if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                        return '/login';
                    } else {
                        return true;
                    }
                },
            },
        ]
    },
    {
        path: "/register",
        component: ()=>import(/*webpackChunkName: "register" */"../pages/Register.vue"),
        name: "Register",
    },
    {
        path: "/reset-request",
        component: ()=>import(/*webpackChunkName: "reset-request" */"../pages/ResetRequest.vue"),
        name: "ResetRequest",
    },
    {
        path: "/reset-password",
        component: ()=>import(/*webpackChunkName: "reset-password" */"../pages/ResetPassword.vue"),
        name: "ResetPassword",
        props: (route) => ({
            email: route.query.email,
            token: route.query.token
        }),
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/unsubscribe-success",
        component: ()=>import(/*webpackChunkName: "unsubscribe-success" */"../pages/UnsubscribeSuccessPage.vue"),
        name: "UnsubscribeSuccessPage",
        // props: (route) => ({
        //     email: route.query.email,
        //     token: route.query.token
        // }),
    },
    {
        path: "/super-admin",
        component: ()=>import(/*webpackChunkName: "super-admin" */"../pages/admin/SuperAdmin.vue"),
        name: "SuperAdmin",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            // Logged in user is a Super Admin
            if (loggedInUser.role_id === 1) {
                return true;
            } else {
                return '/'
            }
        },
    },
    {
        path: "/content-admin",
        component: ()=>import(/*webpackChunkName: "content-admin" */"../pages/admin/ContentAdmin.vue"),
        name: "ContentAdmin",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            // Logged in user is a Super Admin or Content Manager
            if (loggedInUser.role_id === 1 || loggedInUser.role_id === 2) {
                return true;
            } else {
                return '/';
            }
        },
    },
    {
        path: "/media-files-dashboard",
        component: ()=>import(/*webpackChunkName: "media-files-dashboard" */"../pages/admin/MediaFilesDashboard.vue"),
        name: "MediaFilesDashboard",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            // Logged in user is a Super Admin or Content Manager
            if (loggedInUser.role_id === 1 || loggedInUser.role_id === 2) {
                return true;
            } else {
                return '/';
            }
        },
    },
    {
        path: "/document-files-dashboard",
        component: ()=>import(/*webpackChunkName: "document-files-dashboard" */"../pages/admin/DocumentFilesDashboard.vue"),
        name: "DocumentFilesDashboard",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            // Logged in user is a Super Admin or Content Manager
            if (loggedInUser.role_id === 1 || loggedInUser.role_id === 2) {
                return true;
            } else {
                return '/';
            }
        },
    },
    {
        path: "/proprietary-files-dashboard",
        component: ()=>import(/*webpackChunkName: "proprietary-files-dashboard" */"../pages/admin/ProprietaryFilesDashboard.vue"),
        name: "ProprietaryFilesDashboard",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            // Logged in user is a Super Admin or Content Manager
            if (loggedInUser.role_id === 1 || loggedInUser.role_id === 2) {
                return true;
            } else {
                return '/';
            }
        },
    },
    {
        path: "/modules",
        component: ()=>import(/*webpackChunkName: "modules" */"../pages/admin/Modules.vue"),
        name: "Modules",
        beforeRouteEnter () {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            // Logged in user is a Super Admin or Content Manager
            if (loggedInUser.role_id === 1 || loggedInUser.role_id === 2) {
                return true;
            } else {
                return '/';
            }
        }
    },
    {
        path: "/business-formation",
        component: ()=>import(/*webpackChunkName: "business-formation" */"../pages/BusinessFormation.vue"),
        name: "BusinessFormation",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/business-formation';
            } else {
                return true;
            }
        },
    },
    {
        path: "/employee-retention-credit",
        component: ()=>import(/*webpackChunkName: "employee-retention-credit" */"../pages/EmployeeRetentionCredit.vue"),
        name: "EmployeeRetentionCredit",
        alias: ["/solutions/employee-retention-credit","/solutions/training-recruitment-retention"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/employee-retention-credit';
            } else {
                return true;
            }
        },
    },
    {
        path: "/license-enrollment-database-maintenance",
        component: ()=>import(/*webpackChunkName: "license-enrollment-database-maintenance" */"../pages/LicenseEnrollmentDatabaseMaintenance.vue"),
        name: "LicenseEnrollmentDatabaseMaintenance",
        alias: ["/solutions/license-enrollment-database-maintenance","/solutions/licensing-regulatory-support"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/license-enrollment-database-maintenance';
            } else {
                return true;
            }
        },
    },
    {

        path: "/policy-procedure-manuals-licensing-guides",
        component: ()=>import(/*webpackChunkName: "policy-procedure-manuals-licensing-guides" */"../pages/PolicyProcedureManualsLicensingGuides.vue"),
        name: "PolicyProcedureManualsLicensingGuides",
        alias: ["/solutions/policy-procedure-manuals-licensing-guides"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/policy-procedure-manuals-licensing-guides';
            } else {
                return true;
            }
        },
    },
    {

        path: "/hr-documents-forms-templates",
        component: ()=>import(/*webpackChunkName: "hr-documents-forms-templates" */"../pages/HRDocumentsFormsTemplates.vue"),
        name: "HrDocumentsFormsTemplates",
        alias: ["/solutions/hr-documents-forms-templates"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/covid-19-resources",
        component: ()=>import(/*webpackChunkName: "covid-19-resources" */"../pages/Covid19Resources.vue"),
        name: "Covid19Resources",
        alias: ["/solutions/covid-19-resources"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/training-recruitment-retention",
        component: ()=>import(/*webpackChunkName: "training-recruitment-retention" */"../pages/TrainingRecruitmentRetention.vue"),
        name: "TrainingRecruitmentRetention",
        alias: ["/solutions/training-recruitment-retention"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/news-insights",
        component: ()=>import(/*webpackChunkName: "news-insights" */"../pages/NewsAndInsights.vue"),
        name: "NewsAndInsights",
        alias: ["/solutions/news-insights"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/state-specific-employment-resources-templates",
        component: ()=>import(/*webpackChunkName: "state-specific-employment-resources-templates" */"../pages/StateSpecificEmploymentResourcesTemplates.vue"),
        name: "StateSpecificEmploymentResourcesTemplates",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/hipaa-data-privacy-security",
        component: ()=>import(/*webpackChunkName: "hipaa-data-privacy-security" */"../pages/HIPAADataPrivacyAndSecurity.vue"),
        name: "HIPAADataPrivacyAndSecurity",
        alias: ["/solutions/hipaa-data-privacy-security"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/my-proprietary-documents",
        component: ()=>import(/*webpackChunkName: "my-proprietary-documents" */"../pages/MyProprietaryDocuments.vue"),
        name: "MyProprietaryDocuments",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/mergers-and-acquisitions",
        component: ()=>import(/*webpackChunkName: "mergers-and-acquisitions" */"../pages/MergersAndAcquisitions.vue"),
        name: "MergersAndAcquisitions",
        alias: ["/solutions/mergers-acquisitions"],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/events",
        component: ()=>import(/*webpackChunkName: "events" */"../pages/Events.vue"),
        name: "Events",
        alias: ['/solutions/upcoming-events-recordings'],
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/organizations",
        component: ()=>import(/*webpackChunkName: "organizations" */"../pages/admin/Organisations.vue"),
        name: "Organizations",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            if (loggedInUser.role_id === 1) {
                return true;
            } else {
                return '/'
            }
        },
    },
    {
        path: "/organization-groups",
        component: ()=>import(/*webpackChunkName: "organization-groups" */"../pages/admin/OrganisationGroups.vue"),
        name: "OrganizationGroups",
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            if (loggedInUser.role_id === 1) {
                return true;
            } else {
                return '/'
            }
        },
    },
    {
        path: "/solutions/mergers-acquisitions_1/:name?",
        component: ()=>import(/*webpackChunkName: "mergers-acquisitions_1" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "MergersAcquisitions",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/franchise/:name?",
        component: ()=>import(/*webpackChunkName: "franchise" */"../components/subscriber-flow/Imanage-components/FranchiseContent.vue"),
        name: "Franchise",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/news/:name?",
        component: ()=>import(/*webpackChunkName: "news" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "News",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/insights/:name?",
        component: ()=>import(/*webpackChunkName: "insights" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "Insights",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/webinars/:name?",
        component: ()=>import(/*webpackChunkName: "webinars" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "Webinars",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/state-specific-templates-reference/:name?",
        component: ()=>import(/*webpackChunkName: "state-specific-templates-reference" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "StateSpecificTemplatesReference",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/general-employment-templates-and-reference/:name?",
        component: ()=>import(/*webpackChunkName: "general-employment-templates-and-reference" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "GeneralEmploymentTemplatesAndReference",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/upcoming-events/:name?",
        component: ()=>import(/*webpackChunkName: "upcoming-events" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "UpcomingEvents",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/recorded-events/:name?/:id?",
        component: ()=>import(/*webpackChunkName: "recorded-events" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "RecordedEvents",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/covid-19-response-package/:name?",
        component: ()=>import(/*webpackChunkName: "covid-19-response-package" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "Covid19ResponsePackage",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/hipaa-data-privacy-security_1_1/:name?",
        component: ()=>import(/*webpackChunkName: "hipaa-data-privacy-security_1_1" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "HIPAADataPrivacySecurity",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },
    },
    {
        path: "/solutions/proprietary/:name?/:id?",
        component: ()=>import(/*webpackChunkName: "proprietary" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "Proprietary",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else if(!loggedInUser && !SelectedOrganization){
                localStorage.clear();
                return true;
            }
            else{
                return true;
            }
        },
    },
    {
        path: "/solutions/proprietary-state-specific/:name?/:id?",
        component: ()=>import(/*webpackChunkName: "proprietary-state-specific" */"../components/subscriber-flow/Imanage-components/ContentViewer.vue"),
        name: "ProprietaryStateSpecific",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },   
    },
    {
        path: "/storage/imanage/:path",
        component: ()=>import(/*webpackChunkName: "proprietary-state-specific" */"../pages/Download.vue"),
        name: "file.download",
        props: true,
        beforeEnter: () => {
            const loggedInUser = JSON.parse(localStorage.getItem('user'))?.user;
            const SelectedOrganization = JSON.parse(localStorage.getItem('SelectedOrganization'));
            if (loggedInUser && !SelectedOrganization && loggedInUser.role_id !== 1 && loggedInUser.role_id !== 2) {
                localStorage.clear();
                return '/login';
            } else {
                return true;
            }
        },   
    },  
    {
        path: "/privacy-policy",
        component: ()=>import(/*webpackChunkName: "privacy-policy" */"../pages/PrivacyPolicy.vue"), 
        name: "PrivacyPolicy",
    }
];

export default routes;
