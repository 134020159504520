import api from "../../services/client";

/* eslint-disable */
export default {
    namespaced: true,
    state: {
        authenticated: false,
        current_user_id: null,
        user: [],
        users: [],
        errors: null,
        emailError: {
            success: false,
            msg: null,
        },
        mobileError: {
            success: false,
            msg: null,
        },
        passWordError: {
            success: false,
            msg: null,
        },
        loginError: {
            status: false,
            message: null,
        },
        currentYear: new Date().getFullYear(),
        disabled: true,
        loading: false,
        bulk: []
    },
    getters: {
        loggedIn: function(state) {
            return state.userdata !== null
        },
        getBulkUser: function(state) {
            return state.bulk;
        }
    },
    mutations: {
        add(state, user) {
            state.user = user;
        },
        setUser(state, user) {
            state.user = user;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        emailError(state, errors) {
            state.emailError.success = errors.success;
            state.emailError.msg = errors.msg;
        },
        mobileError(state, errors) {
            state.mobileError.success = errors.success;
            state.mobileError.msg = errors.msg;
        },
        passWordError(state, errors) {
            state.passWordError.success = errors.success;
            state.passWordError.msg = errors.msg;
        },
        loginError(state, errors) {
            state.loginError.status = errors.status;
            state.loginError.message = errors.message;
        },
        authenticated(state, status) {
            state.authenticated = status;
        },
        getBulkUser(state, data) {
            state.bulk = data;
        },
        current_user_id(state, id) {
            state.current_user_id = id;
        }
    },
    actions: {
        async register({ commit }, data) {
            commit('setLoading', true);
            try {
                let result = await api.post("/register", data);
                
                commit('setLoading', false);
                return { status: true, res: result.data }
            } catch (error) {
                commit('setLoading', false);
                console.log(error);
                if (error.response.data.errors) {
                    if (error.response.data.errors.email) {
                        let emailError = { status: true, msg: error.response.data.errors.email[0] };
                        commit('emailError', emailError);
                    }
                    if (error.response.data.errors.mobile) {
                        let mobileError = { status: true, msg: error.response.data.errors.mobile[0] };
                        commit('mobileError', mobileError);
                    }

                    if (error.response.data.errors.password) {
                        let passWordError = { status: true, msg: error.response.data.errors.password[0] };
                        commit('passWordError', passWordError);
                    }

                    if (error.response.data.errors.password_confirm) {
                        error.response.data.errors.password_confirm[0];
                    }
                    return { status: false, error: error.response.data }
                }
            }
        },
        async proxyLogin({ commit }, userId) {
            try {
                let result = await api.post("/users/auth-proxy", userId);

                if (!result.data.token) {
                    return { status: false, user: result.data}
                }

                const token = result.data.token;
                const expiration = result.data.expiration;
                const user_id = result.data.user_id;
                localStorage.setItem("token", token);
                localStorage.setItem('tokenExpiration', expiration);
                localStorage.setItem("user_id", user_id);

                if (result.status == 200) {
                    commit('authenticated', true);
                    commit('setUser', result.data);
                    commit('current_user_id' , result.data.user_id)
                    return { status: true, user: result.data }
                }
            } catch (error) {
                if (error.response.status == 401) {
                    return { status: false, error: error.response.status }
                }
            }
        },
        async login({ commit }, data) {
            return await api.post("/login", data).then(response => {
                const token = response.data.token;
                const expiration = response.data.expiration;
                console.log(response.data.expiration);
                const user_id = response.data.user_id;
                localStorage.setItem("token", token);
                localStorage.setItem('tokenExpiration', expiration);
                localStorage.setItem("user_id", user_id);
                commit('authenticated', true);
                commit('setUser', response.data);
                commit('current_user_id' , user_id);
                return response;
            }).catch(error => {
                console.log(error.response.data.errors)
                return error.response;
            });
        },
        // async login({ commit }, data) {
        //     try {
        //         let result = await api.post("/login", data);

        //         if (!result.data.token) {
        //             return { status: false, user: result.data}
        //         }
        //         debugger;

        //         const token = result.data.token;
        //         const expiration = result.data.expiration;
        //         const user_id = result.data.user_id;
        //         localStorage.setItem("token", token);
        //         localStorage.setItem('tokenExpiration', expiration);
        //         localStorage.setItem("user_id", user_id);
                
        //         if (result.status == 200) {
        //             commit('authenticated', true);
        //             commit('setUser', result.data);
        //             commit('current_user_id' , result.data.user_id)
        //             return {status: true, token: result.data.token, user_id: result.data.user_id}
        //         }
        //     } catch (error) {
        //         debugger;
        //         if (error.response.status == 401) {
        //             let emailError = {status: true, msg: "Make sure your email is correct" };
        //             let passWordError = {status: true, msg: "Make sure your password is correct" };
        //             commit('emailError', emailError);
        //             commit('passWordError', passWordError);
        //             return {status: false, error: error.response.status}
        //         }
        //         /**
        //          * 403 error - forbidden
        //          * User is not allowed to login, because user is not active or has been archived.
        //          * Or the user's organization(s) is not active or has been archived.
        //          */
        //         if (error.response.status === 403) {
        //             let loginError = {status: true, message:  error.response.data.message};
        //             commit('loginError', loginError);
        //             return {success: false, error: loginError}
        //         }
        //     }
        // },
        async addusers({ commit }, data) {
            await api.post("/register", {
                data
            }).then(response => {
                commit('setUser', response.data);
            }).catch(error => {
                console.log(error.response.data.errors)
                commit('SetError', true);
            })
        },
        async getuser({ commit }, headers) {
            let data = JSON.stringify({
                "id": state.current_user_id
            });
            try {
                commit('setLoading', true);
                let user = await api.post("/user", { headers, data:data });
                if (user) {
                    localStorage.setItem("authenticate", true);
                    localStorage.setItem("user", JSON.stringify(user.data));
                    return { status: true, user: user.data };
                }
            } catch (error) {
                if (error.response.status == 401) {
                    commit('setLoading', false);
                    let emailError = { status: true, msg: "Make sure your email is correct" };
                    let passWordError = { status: true, msg: "Make sure your password is correct" };
                    commit('emailError', emailError);
                    commit('passWordError', passWordError);
                    return { status: false, error: user.error }
                }
            }
        },
        async getUsers({ commit }, headers) {
            try {           
                let result = await api.get("/users", { headers });
                if (result.status == 200) {
                
                    localStorage.setItem("users", JSON.stringify(result.data.users));
                    commit('setUsers', result.data);
                    return { status: false, users: result.data}
                }
            } catch (error) {
                if (error.response.status == 401) {
                    return { status: false, error: 401 }
                }
            }
        },
        async updateUser({ commit }, data) {
            let headers = data.headers
            try {
                let result = await api.post("/user/update", data.data,{headers});
                //if (result.status == 201) {
                    localStorage.setItem("users", JSON.stringify(result.data.users.original.users));
                //} 
            } catch (error) {
                if (error.response.status == 401) {
                    return { status: false, error: 401 }
                }
            }   
        },
    }
}