<template>
    <div class="updated-user notification" :style="{ backgroundColor: backgroundColor }">
        <span>{{ popMessage }}</span>
    </div>
</template>

<script setup>
import { useUsersStore } from '../../store/usersStore';
import { useOrgStore } from '../../store/orgStore';

const userStore = useUsersStore()
const orgStore = useOrgStore()

const backgroundColor = userStore.msgBackgroundColor
const popMessage = userStore.popMessage || orgStore.popMessage

</script>

<style lang="scss" scoped>
.updated-user.notification {
    position: fixed;
    padding: 8px 10px;
    color: #fff;
    border-radius: 6px;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    // width: max-content;
    max-width: 350px;
    text-align: center;
    box-shadow: 0 5px 10px #00000017;
    font-size: 0.7rem;
    z-index: 100;
}

@media (max-width: 1639px) {
    .updated-user.notification {
        top: 116px;
    }
}

@media (max-width: 1399px) {
    .updated-user.notification {
        top: 160px;
    }
}

@media (max-width: 991px) {
    .updated-user.notification {
        top: 75px;
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
