import { defineStore } from 'pinia'
import api from "../services/client"

export const useResetStore = defineStore('resetStore', {
state: () => ({
  email: null,
  password: null,
  confirmPassword: null,
  resetResponse: null,
  responseMessage: {
    type: null,
    text: null
  },
}),

getters: {

},

actions: {
  setEmail(email) {
    this.email = email;
  },
  setPassword(password) {
    this.password = password;
  },
  
  clearResponseMessage() {
    setTimeout(() => {
      this.responseMessage.type = null;
      this.responseMessage.text = null;
    }, 4000);
  },

  async sendResetPasswordLink(data) {
    // Here, you can implement your reset password logic
      const url = '/send/reset-password/link';
      // Set your custom headers here
      const headers = {
        'Content-Type': 'application/json',
      };

      // Make the API call using Axios
      return api.post(url, data, { headers })
        .then(response => { 
          // Handle successful response 
        
          // You can also update the state or do other actions as needed
          return response;
        })
        .catch(error => {
          // Handle error
          console.error('Failed to reset password:', error);
          return error.response;
        });   
  },

  async resetPassword(data) {
    // Here, you can implement your reset password logic
      const url = '/reset-password';
      // Set your custom headers here
      const headers = {
        'Content-Type': 'application/json',
      };

      // Make the API call using Axios
      return api.post(url, data, { headers })
        .then(response => {
          // Handle successful response
          this.responseMessage.type = 'success';
          this.responseMessage.text = 'Password reset successfully';
          this.clearResponseMessage();
          // You can also update the state or do other actions as needed
          return response.data;
        })
        .catch(error => {
          // Handle error
          console.error('Failed to reset password:', error);
          this.responseMessage.type = 'error';
          this.responseMessage.text = 'Password reset failed';
          this.clearResponseMessage();
          return error;
        });   
  },

  async submitRequestDemo(data) {
    // Here, you can implement your reset password logic
      const url = '/demo/request';
      // Set your custom headers here
      const headers = {
        'Content-Type': 'application/json',
      };

      // Make the API call using Axios
      return api.post(url, data, { headers })
        .then(response => {
          // Handle successful response
          this.responseMessage.type = 'success';  
          this.responseMessage.text = 'Demo request submitted successfully';
          this.clearResponseMessage();
          // You can also update the state or do other actions as needed
          return response.data;
        })
        .catch(error => {
          // Handle error
          this.responseMessage.type = 'error';
          this.responseMessage.text = `Demo request failed. ${error.response.data.message}`;
          this.clearResponseMessage();
          console.error('Failed demo request:', error);
          return error;
        });   
  },
  /**
   * Submits a request for assistance.
   * 
   * @param {Object} data - The data to be sent in the request.
   * @returns {Promise} A promise that resolves to the response data if the request is successful, or rejects with an error if the request fails.
   */
  async submitRequestAsst(data) {
    // Here, you can implement your reset password logic
      const url = '/assistance/request';
      // Set your custom headers here
      const headers = {
        'Content-Type': 'application/json',
      };

      // Make the API call using Axios
      return api.post(url, data, { headers })
        .then(response => {
          // Handle successful response
          this.responseMessage.type = 'success';
          this.responseMessage.text = `Your request has been received and is being reviewed by our support team. 
                              We will get back to you within 1-2 business days. 
                              Thank you for your patience. Our support department does not operate on the weekends or holidays.`
          this.clearResponseMessage();
          // You can also update the state or do other actions as needed
          return response.data;
        })
        .catch(error => {
          // Handle error
          this.responseMessage.type = 'error';
          this.responseMessage.text =  `Assistance request failed. ${error.response.data.message}`;
          this.clearResponseMessage();
          console.error('Failed assistance request:', error);
          return error;
        });   
  },
  async contactRequest(data) {
    // Here, you can implement your reset password logic
      const url = '/contact/request';
      // Set your custom headers here
      const headers = {
        'Content-Type': 'application/json',
      };

      // Make the API call using Axios
      return api.post(url, data, { headers })
        .then(response => {
          // Handle successful response
          this.responseMessage.type = 'success';
          this.responseMessage.text = 'Contact request submitted successfully';
          this.clearResponseMessage();
          // You can also update the state or do other actions as needed
          return response.data;
        })
        .catch(error => {
          // Handle error
          this.responseMessage.type = 'error';
          this.responseMessage.text = `Contact request failed. ${error.response.data.message}`;
          this.clearResponseMessage();
          console.error('Failed contact request:', error);
          return error;
        });   
  },
 } 
})