import Dexie from 'dexie';

export const db = new Dexie('poshDatabase');
db.version(1).stores({
  content: '++id,contentAllDexie', // Primary key and indexed props
  contentSearch: '++id,contentSearchDexie', // indexed props
  usersList: '++id, usersListDexie', // active users list
  archivedUsersList: '++id, archivedUsersListDexie', // archived users list
  organisationsList: '++id, organisationsListDexie', // active organisations list
  archivedOrganisationsList: '++id, archivedOrganisationsListDexie', // archived organisations list
  orgUsersList: '++id, orgUsersListDexie', // active org users list
  orgGroupsList: '++id, orgGroupsListDexie', // active org groups list
  archivedOrgGroupsList: '++id, archivedOrgGroupsListDexie', // archived org groups list
});
