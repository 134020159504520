import api from "../../services/client";

/* eslint-disable */
export default {
    namespaced: true,
    state: {
        roles: [],
        organs: [],
    },
    getters: {

    },
    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        },   
        setOrgans(state, organs) {
            state.organs = organs;
        },           
    },
    actions: {    
        async getRoles({ commit }, headers) {
            try {           
                let result = await api.get("/role/index", { headers });
                if (result.status == 200) {
                   
                    localStorage.setItem("roles", JSON.stringify(result.data));
                    commit('setRoles', result.data);
                    return { status: false, roles: result.data}
                }
            } catch (error) {
                if (error.response.status == 401) {
                    return { status: false, error: 401 }
                }
            }
        },
        async getOrgans({ commit }, headers) {
            try {           
                let result = await api.get("/organisation/index", { headers });
                if (result.status == 200) {
              
                    localStorage.setItem("organs", JSON.stringify(result.data));
                    commit('setOrgans', result.data);
                    return { status: false, organs: result.data}
                }
            } catch (error) {
                if (error.response.status == 401) {
                    return { status: false, error: 401 }
                }
            }
        },
    }
}