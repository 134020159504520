<script setup>
import { ref } from "vue";
import PoshToast from "../../components/notifications/PoshToast.vue";
import { useResetStore } from "../../store/resetStore";
import { useUsersStore } from "../../store/usersStore";
import {
    useIsValidEmail,
    isValidPhoneNumber,
    useIsValidName,
} from "../../composables/inputValidators";
import { useRouter } from "vue-router";

const emits = defineEmits(["success"]);

const router = useRouter();
const ResetStore = useResetStore();
const usersStore = useUsersStore();
const showPop = ref(false);

const first_name = ref("");
const last_name = ref("")
const email = ref("")
const confirm_email = ref(null)
const phone = ref("")
const referral = ref("")
const job_title = ref("")
const client_status = ref("");
const contact_by = ref("email");
const entity = ref("");
const desc = ref("");
const policy = ref(false);
const submitText = ref("Submit");

const nameError = ref(null)
const surnameError = ref(null)
const emailError = ref(null)
const jobError = ref(null)
const confirmEError = ref(null)
const phoneError = ref(null)
const contactError = ref(null)
const clientError = ref(null)
const entityError = ref(null)
const policyError = ref(null)
// const status = ref("")
const submittingForm = ref(false)

const requiredFieldsCompleted = () => {
    return (
        first_name.value &&
        last_name.value &&
        email.value &&
        confirm_email.value &&
        //phone.value &&
        // contact_by.value &&
        client_status.value &&
        policy.value
    );
}

const validateName = (field) => {
    if (field === 'firstName') {
        if (!useIsValidName(first_name.value)) {
            nameError.value = 'First name may only contain letters, spaces, apostrophes, and hyphens.'
        } else {
            nameError.value = '';
        }
    }

    if (field === 'lastName') {
        if (!useIsValidName(last_name.value)) {
            surnameError.value = 'Last name may only contain letters, spaces, apostrophes, and hyphens.'
        } else {
            surnameError.value = '';
        }
    }
}

/**
     * Checks whether the given input string is valid email addres.
     * @param {String} field input string to validate
     */
const validateEmail = (field) => {
    if (field === 'email') {
        const emailLowerCase = email.value.toLowerCase();
        if (useIsValidEmail(emailLowerCase)) {
            emailError.value = '';
        } else {
            emailError.value = 'Invalid email address.'
        }
        return;
    }

    if (field === 'confirm email') {
        const emailLowerCase = confirm_email.value.toLowerCase();
        if (useIsValidEmail(emailLowerCase)) {
            confirmEError.value = '';
        } else {
            confirmEError.value = 'Invalid email address.'
        }
        return;
    }
}

const validatePhoneNumber = () => {
    if (!isValidPhoneNumber(phone.value) && phone.value.length < 1) {
        phoneError.value = '';
    } else if (!isValidPhoneNumber(phone.value) && phone.value.length > 0) {
        phoneError.value = 'Phone number must be 10 digits long with no spaces in between.';
    } else {
        phoneError.value = '';
    }
}

const validatePolicy = () => {
    if (!policy.value) {
        policyError.value = 'You must accept the terms of the privacy policy to submit the form.'
    } else {
        policyError.value = '';
    }
}

const validateClientStatus = () => {
    if (!client_status.value) {
        clientError.value = 'You must select whether you are a current Polsinelli client to submit the form.'
    } else {
        clientError.value = '';
    }
}

/**
 * Checks if there is an error message for any of the form fields.
 * @returns {boolean} True if there is an error message, false otherwise.
 */
const hasErrorMessage = () => {
    return (
        nameError.value ||
        surnameError.value ||
        emailError.value ||
        confirmEError.value ||
        phoneError.value ||
        contactError.value ||
        clientError.value ||
        entityError.value ||
        policyError.value
    );
}

const submitRequestDemo = () => {
    validateName('firstName');
    validateName('lastName');
    validateEmail('email');
    validateEmail('confirm email');
    validatePolicy();
    validateClientStatus();
    if (phone.value.length > 0) {
        validatePhoneNumber();
    }

    if (!hasErrorMessage() && requiredFieldsCompleted()){
        submitText.value = "Submitting..."
        const data = {
            first_name: first_name.value,
            last_name: last_name.value,
            job_title: job_title.value,
            entity: entity.value,
            email: email.value,
            phone: phone.value,
            client_status: client_status.value,
            contact_by: contact_by.value,
            description: desc.value,
            referral: referral.value,
            policy: policy.value
        }

        ResetStore.submitRequestDemo(data).then((res) => {
            showMsg(res)
        });
    }
}

const showMsg = (res) => {
    showPop.value = true,
        submitText.value = "Submit",
        usersStore.msgBackgroundColor = "#008000",
        usersStore.popMessage = res.message,

        setTimeout(() => {
            first_name.value = "";
            last_name.value = ""
            job_title.value = ""
            entity.value = ""
            email.value = ""
            confirm_email.value = ""
            phone.value = ""
            client_status.value = ""
            contact_by.value = "email"
            desc.value = ""
            referral.value = ""
            policy.value = false
            showPop.value = false
            router.push({ name: "Homepage" });
            emits('success');
        }, 3000);
}
</script>

<template>
    <div class="request-demo-form">
        <PoshToast v-if="showPop"></PoshToast>
        <div class="legend">
            <div class="required-dot"></div>
            <span>Required fields</span>
        </div>
        <form @submit.prevent>
            <div class="main-details">
                <div class="input-container required">
                    <input v-model="first_name" type="text" placeholder="First Name" @change="validateName('firstName')" />
                    <div v-if="nameError" class="error-text">{{ nameError }}</div>
                </div>
                <div class="input-container required">
                    <input v-model="last_name" type="text" placeholder="Last Name" @change="validateName('lastName')" />
                    <div v-if="surnameError" class="error-text">{{ surnameError }}</div>
                </div>
                <div class="input-container">
                    <input v-model="job_title" type="text" placeholder="Job Title" />
                    <div v-if="jobError" class="error-text">{{ jobError }}</div>
                </div>
                <div class="input-container">
                    <input v-model="entity" type="text" placeholder="Business Name" />
                    <div v-if="entityError" class="error-text">{{ entityError }}</div>
                </div>
                <div class="input-container required">
                    <input v-model="email" type="email" placeholder="Email Address" @change="validateEmail('email')" />
                    <div v-if="emailError" class="error-text">{{ emailError }}</div>
                </div>
                <div class="input-container required">
                    <input v-model="confirm_email" type="email" placeholder="Confirm Email Address"
                        @change="validateEmail('confirm email')" />
                    <div v-if="confirmEError" class="error-text">{{ confirmEError }}</div>
                </div>
                <div class="input-container">
                    <input v-model="phone" type="tel" placeholder="Phone Number" @input="validatePhoneNumber" />
                    <div v-if="phoneError" class="error-text">{{ phoneError }}</div>
                </div>
            </div>
            <div class="client-question">
                <div class="required">
                    Are you a current Polsinelli client?
                </div>
                <div class="inputs">
                    <input id="yes" v-model="client_status" type="radio" value="1" />
                    <label for="yes">Yes</label>
                    <input id="no" v-model="client_status" type="radio" value="0" />
                    <label for="no">No</label>
                </div>
            </div>
            <div v-if="clientError" class="error-text">{{ clientError }}</div>
            <div class="input-container additional-information">
                <textarea id="demo-additional-information" v-model="desc" rows="5"
                    placeholder="Additional Information"></textarea>
            </div>
            <div class="input-container marketing-source">
                <label for="input">Where/how did you hear about us?</label>
                <input v-model="referral" type="text" />
            </div>
            <div class="terms-check required">
                <input id="demo-terms" v-model="policy" type="checkbox" value="1" />
                <label for="demo-terms">By submitting this form, I confirm my consent to
                    processing and accept the terms of
                    <RouterLink to="/privacy-policy">
                        <a href="" target="_blank">Polsinelli’s PrivacyPolicy</a>.
                    </RouterLink>
                </label>
            </div>

            <div v-if="policyError" class="error-text">{{ policyError }}</div>
        </form>
        <div class="button-container">
            <button v-if="submittingForm" disabled class="btn secondary-btn">
                Submitting...
            </button>
            <button v-else tabindex="0" :disabled="!requiredFieldsCompleted()" class="btn secondary-btn"
                @click="submitRequestDemo">
                {{ submitText }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
div.error-text {
    font-size: 0.7rem;
    color: #9F3037;
    margin-bottom: 5px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: 99999;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-card {
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 100%;
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
}

.close-button {
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 5px;
    background: #313e47;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        filter: invert(1);
        max-width: 100%;
        height: auto;
    }
}

.form-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    h3 {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.5;
        margin: 0;
        color: #cbcbcb;

        &.active {
            color: #28323a;
            font-weight: 600;
        }
    }

    .seperator {
        width: 1px;
        height: 1.5rem;
        background: #cbcbcb;
        margin: 0 1rem;
    }
}

.legend {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    .required-dot {
        width: 6px;
        height: 6px;
        background: #9f3037;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
    }

    span {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #9f3037;
    }
}

.main-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
}

.input-container {
    position: relative;

    &.required::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: #9f3037;
        border-radius: 50%;
        top: -9px;
        right: 0;
    }
}

.client-question {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    div {
        font-size: 0.875rem;
        font-weight: 600;
        color: #28323a;
        position: relative;
        display: inline-block;
        width: max-content;
        margin-bottom: 0.2rem;

        &.required::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: #9f3037;
            border-radius: 50%;
            top: -2px;
            right: -8px;
        }
    }

    .inputs {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        gap: 2px 10px;

        input[type="radio"] {
            height: 1rem;
            width: 1rem;
        }

        label {
            margin: 0;
        }
    }
}

.additional-information {
    margin-bottom: 1rem;
}

.marketing-source {
    margin-bottom: 1rem;

    label {
        font-size: 0.875rem;
        font-weight: 600;
    }
}

.terms-check {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 1rem;

    input[type="checkbox"] {
        height: 22px;
        width: 22px;
        font-size: 1rem;
        padding: 0px;
    }

    &.required::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: #9f3037;
        border-radius: 50%;
        top: -2px;
        right: 0;
    }
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.btn:disabled {
    cursor: not-allowed;
}

.select-container {
    position: relative;
}

.select-container select {
    -webkit-appearance: none;
    background-color: #fff;
}

.select-arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    pointer-events: none;
    z-index: 10;
    background: url("/assets/icons/chevron-down.svg") no-repeat center center/contain;
}

@media (max-width: 768px) {
    .modal-card {
        padding: 10px;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1rem;
    }

    .main-details {
        grid-template-columns: 1fr;
    }

    .client-question,
    .terms-check,
    .form-title,
    .legend,
    .marketing-source,
    .additional-information {
        margin-bottom: 0.5rem;
    }
}
</style>

