
/**
 *  The regex provided attempts to match words and double-barrelled words using word characters, hyphens, and apostrophes.
 *  However, keep in mind that real-world names and words can have various nuances and cultural differences, 
 *  so this regex may not capture all possible variations.
 * @param {String} name string to check whether is a valid name
 * @returns returns true, indicating that the string is a valid name else returns false.
 */
export function useIsValidName(name) {
    return RegExp(/^(?:[A-Za-z\-' ]+)(?: [A-Za-z\-']{2,})?$/, 'g').test(name);
}

/**
 * Returns a boolean value that indicates whether the given email string is a valid email address.
 * @param {String} email email address to validate
 * @returns Boolean
 */
export function useIsValidEmail(email) {
    return RegExp(/[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'g').test(email);
}

/**
 * Returns a boolean value indicating whether the given input contains digits only.
 * @param {String} input input to validate
 * @returns  returns a boolean value indicating the result.
 */
export function useIsDigitsOnly(input) {
    return RegExp('^[0-9]+$', 'g').test(input);
}

/**
 * Returns a boolean value indicating whether the given input is a valid longitude format.
 * @param {String} input input to validate
 * @returns  returns a boolean value indicating the result.
 */
export function useIsValidLongitude(input) {
    return RegExp(/^[-+]?([1-9]?[0-9]{1,2}(\.\d+)?|1[0-7][0-9]{1,2}(\.\d+)?|180)$/, 'g').test(input);
}

/**
 * Returns a boolean value indicating whether the given input is a valid latitude format.
 * @param {String} input input to validate
 * @returns  returns a boolean value indicating the result.
 */
export function useIsValidLatitude(input) {
    return RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, 'g').test(input);
}

/**
 * Checks if the input is a valid phone number.
 * @param {string} input - The input to be validated.
 * @returns {boolean} - Returns true if the input is a valid phone number, otherwise false.
 */
export function isValidPhoneNumber(input) {
    return RegExp(/^\d{10}$/, 'g').test(input);
}

/**
 * Checks if the input is a valid American phone number in the format XXX-XXX-XXXX.
 * @param {string} input - The input to be validated.
 * @returns {boolean} - Returns true if the input is a valid American phone number, otherwise returns false.
 */
export function isAmericanPhoneNumber(input) {
    return RegExp(/^\d{3}-\d{3}-\d{4}$/, 'g').test(input);;
}

/**
 * Returns a boolean value indicating whether the given input is a valid address format.
 * @param {String} input input to validate
 * @returns  returns a boolean value indicating the result.
 */
export function useIsValidAddress(input) {
    // Modify the regular expression pattern to match valid address formats
    return RegExp(/^[0-9]+\s[A-Za-z\s]+,\s[A-Za-z\s]+,\s[A-Za-z\s]+,\s[A-Za-z\s]+$/, 'g').test(input);
}

/**
 * Returns a boolean value indicating whether the given input is a valid contact person format.
 * @param {String} input input to validate
 * @returns  returns a boolean value indicating the result.
 */
export function useIsValidContactPerson(input) {
    // Modify the regular expression pattern to match valid contact person formats
    return RegExp(/^[A-Za-z\s]+$/, 'g').test(input);
}


/**
 * Checks if the input has a minimum length of 14 characters.
 * 
 * @param {string} input - The input to be validated.
 * @returns {boolean} - Returns true if the input has a minimum length of 14 characters, otherwise returns false.
 */
export function hasMinLength(input) {
    return RegExp(/^.{14,}$/).test(input);
}

/**
 * Checks if the input string contains at least one lowercase letter.
 * 
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - Returns true if the input contains at least one lowercase letter, otherwise returns false.
 */
export function hasLowercase(input) {
    return RegExp(/(?=.*[a-z])/).test(input);
}

/**
 * Checks if the input string contains at least one uppercase letter.
 * 
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - Returns true if the input contains at least one uppercase letter, otherwise returns false.
 */
export function hasUppercase(input) {
    return RegExp(/(?=.*[A-Z])/).test(input);
}

/**
 * Checks if the input string contains at least one number.
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - Returns true if the input string contains at least one number, otherwise returns false.
 */
export function hasNumber(input) {
    return RegExp(/(?=.*\d)/).test(input);
}

/**
 * Checks if the input string contains any special characters.
 * 
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - Returns true if the input string contains special characters, otherwise returns false.
 */
export function hasSpecialCharacter(input) {
    return RegExp(/(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-])/).test(input);
}

/**
 * Checks if the input string contains whitespace.
 * 
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - Returns true if the input string contains whitespace, otherwise false.
 */
export function hasWhitespace(input) {
    return RegExp(/\s/).test(input);
}