<template>
    <main :class="pageClass">
        <MainNav v-if="!isLoginPage && !showTermsAndCondtions" />
        <router-view></router-view>
        <div v-if="isHomePage">
            <HomeBanner />
            <!-- <div class="downArrow">
                <img src="../../../public/assets/icons/downArrow.svg" />
            </div>        -->
            <UpcomingEvents />
            <ContentSection />
            <div class="svgWrapper">
                <div class="upcomingEventsOuter">
                    <h6 class="redSmall">
                        Click on a state to see the relevant state documents.
                    </h6>
                    <h1 class="blackBig">State Specific Forms & Templates</h1>
                    <MapComponent />
                </div>
            </div>
        </div>
        <Footer v-if="!isLoginPage"></Footer>
    </main>
    <Teleport to="body">
        <TermsAndConditions v-if="showTermsAndCondtions" />
    </Teleport>
    <Teleport to="body">
        <CookiesInformation />
    </Teleport>
</template>

<script setup>
import HomeBanner from "../components/hero/HomeBanner.vue";
import TermsAndConditions from "../components/notifications/TermsAndConditions.vue";
import CookiesInformation from "../components/notifications/CookiesInformation.vue";
const Footer = defineAsyncComponent(() => import('../components/footer/footer.vue'));
const MainNav = defineAsyncComponent(() => import('../components/navigation/SubscriberNav.vue'));
/* const HomeBanner = defineAsyncComponent(() => import('../components/hero/HomeBanner.vue')); */
const UpcomingEvents = defineAsyncComponent(() => import('../components/subscriber/UpcomingEvents.vue'));
const ContentSection = defineAsyncComponent(() => import('../components/subscriber/ContentSection.vue'));
const MapComponent = defineAsyncComponent(() => import('../components/subscriber/MapComponent.vue'));
/* import Footer from "../components/footer/footer.vue";
import MainNav from "../components/navigation/SubscriberNav.vue";
import HomeBanner from "../components/hero/HomeBanner.vue";
import UpcomingEvents from "../components/subscriber/UpcomingEvents.vue";
import ContentSection from "../components/subscriber/ContentSection.vue";
import MapComponent from "../components/subscriber/MapComponent.vue"; */
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from 'vue-router';
import { useUsersStore } from "../store/usersStore";
import { storeToRefs } from "pinia";

const usersStore = useUsersStore();
const { isProxyLoggedIn } = storeToRefs(usersStore);
const route = useRoute();
/* const path = ref(route?.value?.path ?? ''); */

const isHomePage = computed(() => route.name == 'Homepage');
const isLoginPage = computed(() => route.path == '/login');
const pageClass = computed(() => route.path == '/login' ? 'loginpage' : 'homepage');


const userLoggedIn = computed(() => {
    return usersStore.getLoggedInUser ? true : false;
});

/**
 * Computed property that determines whether to show the terms and conditions.
 * Only shows if the user is logged in, has not accepted the terms and conditions, is a subscriber, and is not a proxy user.
 * 
 * @returns {boolean} True if the terms and conditions should be shown, false otherwise.
 */
const showTermsAndCondtions = computed(() => {
    const currentUser = usersStore.getLoggedInUser?.user;
    return userLoggedIn.value && 
        currentUser.terms === 0 && 
        usersStore.isSubscriberUser &&
        !isProxyLoggedIn.value;
});

</script>

<style>
.downArrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 4rem 0rem;
}

.svgWrapper {
    /*     width: 100vw;
    height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.redSmall {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #9f3037;
}

.blackBig {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 48px;
    font-weight: 600;
    color: #000;
    justify-self: center;
}

.upcomingEventsOuter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
    background-color: #f5f5f5;
    background-color: #ffffff;
    /*     height: 100%; */
    /*     margin-bottom: 600px;
    margin-top: 300px; */
    width: 100%;
    max-width: 1400px;
}
</style>
