<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUsersStore } from '../../store/usersStore';
import { useStore } from 'vuex';
import apiClient from '../../services/client';
import { storeToRefs } from 'pinia';

const props = defineProps({
    viewTerms: Boolean
});

const emit = defineEmits(['closeTerms']);

const $store = useStore();
const $router = useRouter();
const usersStore = useUsersStore();
const { isSubscriberUser, isAdministratorUser,isProxyLoggedIn } = storeToRefs(usersStore);
const showTermsAndConditions = ref(false);

const currentUser = usersStore.getLoggedInUser?.user;

onMounted(() => {
    checkIfUserHasAcceptedTerms();
});

const isLoggedIn = computed(() => {
    return currentUser !== null && currentUser !== undefined;
});

const acceptedTerms = computed(() => {
    return isLoggedIn.value && currentUser.terms === 1;
});

const checkIfUserHasAcceptedTerms = () => {
    if (currentUser && !acceptedTerms.value && usersStore.isSubscriberUser){
        showTermsAndConditions.value = true;
    }
};
/**
 * Function to handle accepting the terms and conditions.
 */
const acceptTerms = () => {
    showTermsAndConditions.value = false;
    // Call API to update user terms
    const headers =  {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const config = {
        method: "POST",
        url: "/user/update/termsnconditions",
        headers: headers,
        data: {
            terms: true
        }
    };
    apiClient(config).then(() => {
        usersStore.fetchUser(currentUser.id);
    }).catch(error => {
        console.error(error);
    });
}

/**
 * Function to reject the terms and conditions.
 * @returns {void}
 */
const rejectTerms = () => {
    showTermsAndConditions.value = false;
    localStorage.clear();
    $store.state.user.authenticated = false;
    usersStore.setSelectedOrganizationToNull(null);
    usersStore.setUserToNull(null);
    // Log user out and redirect to login page
    $router.push({ name: "Homepage" });
    apiClient.post('/polsinelli/logout').then(() => {
        $store.state.user.authenticated = false;
        localStorage.clear();
        $router.push({ name: "Homepage" });
        usersStore.setSelectedOrganizationToNull(null);
        usersStore.setUserToNull(null);
        usersStore.clearToken();
    }).catch(error => {
        console.log(error);
    });
};

const closeTerms = () => {
    showTermsAndConditions.value = false;
    if (props.viewTerms) {
        emit('closeTerms');
    }
};

</script>

<template>
    <div class="terms-and-conditions-wrapper">
        <section>
            <div v-if="showTermsAndConditions || viewTerms" class="terms-modal add-modal">
                <div class="terms-banner">
                    <div class="text-container">
                        <h3>
                            POLSINELLI ONLINE SOLUTIONS FOR HOMECARE TERMS OF SERVICE AGREEMENT
                        </h3>
                    </div>
                </div>
                <div class="terms-body">
                    <section>
                        <p class="text-normal">
                            This Terms of Service Agreement (“Agreement”) is entered into by and between Polsinelli Online Solutions, 
                            LLC dba Polsinelli Online Solutions for Home Care (“POSH”) and the individual or entity accessing the Service, 
                            as defined below (“Licensee”), each individually a "Party” and collectively "Parties”, 
                            as of the date Licensee first accesses the Services (the “Effective Date”). 
                        </p>
                        <h6>BACKGROUND</h6>
                        <p class="text-normal">
                            This Agreement sets forth the terms and conditions that govern the use of the Service and all software, 
                            services and content available therein by Licensee for their own benefit or that of their organization.  
                            By accessing or using the Service in any way or clicking its acceptance of this Agreement, Licensee, 
                            for itself and its organization, agrees to be bound by this Agreement. 
                            If Licensee does not accept the terms of this Agreement Licensee may not use the Service. 
                            Licensee represents and warrants that (i) Licensee is at least 18 years of age, 
                            is located in the United States and has the authority to enter into this Agreement; 
                            and (ii) if Licensee is accepting this Agreement on behalf of an organization, 
                            Licensee has the authority to agree to all of the terms set forth herein on behalf of such organization. 
                        </p>
                        <p class="text-normal">
                            POSH provides home health, home care and hospice providers with a subscription service that includes template forms, tools, 
                            products and services and other resources to help navigate the complex legal and regulatory environment unique to these industries (the “Service”).  
                            The specific elements of the Service that are accessible to Licensee may be described in one or more 
                            Order Forms or similar documents entered by and between Licensee's organization (or the franchisor to Licensee's organization 
                            if Licensee's organization is a franchisee) and POSH.  
                            Licensee understands and agrees that the licenses and permissions granted herein entitle 
                            Licensee to use the Service for the internal use of its organization only.  
                            Licensee shall not use the Service for the benefit of any other party, 
                            even if such other party is affiliated in some way with Licensee or is another franchisee of the same franchisor.  
                        </p>
                        <p class="text-normal">
                            Licensee may make the Service available to its “Authorized Users”, which means each named individual who: 
                            (a) is authorized by Licensee to access and use the Services under Licensee's account; 
                            (b) is an employee or independent contractor of Licensee; and 
                            (c) is issued individual access credentials (e.g., username/password) to enable the individual to log into the Site (as defined in the Standard Terms and Conditions below) and access the Service. 
                        </p>
                        <p class="text-normal">
                            Licensee agrees to remain in compliance with any payment obligations applicable to Licensee set forth in Licensee’s 
                            (or, if applicable, its franchisor’s) order form or similar ordering document for the Service. 
                            The Term of this Agreement will be as set forth in Licensee’s (or, if applicable, its franchisor’s) order form or similar ordering document, 
                            provided that the termination provisions set forth herein shall govern.  For the avoidance of doubt, 
                            if Licensee’s access to the Service is subject to an agreement between POSH and Licensee’s franchisor, 
                            then any termination of such POSH-franchisor agreement will result in the contemporaneous termination of this Agreement. 
                        </p>
                    </section>
                    <section>
                        <h6>STANDARD TERMS AND CONDITIONS</h6>
                        <p class="text-normal">
                            <span class="text-bold">1. Service: </span>
                            The Service includes the POSH website (current address https://online.polsinelli.com/solutions/Home ) (the “Site”), 
                            and certain other websites, portals, sites or applications that are operated by or on behalf of POSH and that 
                            POSH makes available to Licensee by computer or mobile device. 
                            The Site includes permissions-based access to a proprietary area in which Licensee and, if applicable, 
                            Licensee’s franchisor (which may be a client of Polsinelli PC) may upload client-specific materials and 
                            provide access to those materials to Licensee and other Authorized Users (with permissions to access this area) of the Site (the “ Proprietary Environment”).  
                            Another part of the Site contains more generic templates and other non-customized documents that any authorized user of the 
                            Service may use (the “Standard Environment”). POSH may choose in its sole discretion to modify, discontinue, suspend, 
                            or delay the Service or any Site features, functionality or content thereof. 
                            ​​POSH shall make commercially reasonable efforts to maintain the availability of the Site to 
                            Licensee and its Authorized Users 99.5% of the time in any calendar month (excluding scheduled system downtime, 
                            transition to upgraded software, and events outside of the reasonable control of POSH).  
                            POSH shall use commercially reasonable efforts to correct any failure, malfunction,
                            defect or nonconformity which prevents the Site or the Service from performing or being available to 
                            Licensee and its Authorized Users within a reasonable period of time after becoming aware of or receiving notification of the issue. 
                            The Service may include links to other websites or resources provided by third parties (collectively, “Third Party Materials”). 
                            Licensee understands and agrees that POSH does not control the content or the availability of Third Party Materials and, accordingly, 
                            that POSH is not responsible or liable for such Third Party Materials or Licensee’s or any Authorize User’s use thereof.   
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">2. Restrictions on Use of Service and Licensed Materials; Ownership; Assignment:</span>
                            <ol type="A">
                                <li>
                                    <span class="text-bold">A. Use of the Service:</span> 
                                    Licensee will not​,​ and ​​​​will require its Authorized Users to agree as a condition of access, 
                                    that they will not provide any unaffiliated third-party access to the Service. Further, 
                                    Licensee understands and agrees that the access credentials issued hereunder are personal to each named 
                                    Authorized User and may not be shared with other individuals. Licensee may substitute individuals as 
                                    Authorized Users (e.g., if an individual leaves the employ of Licensee) by discontinuing the departing 
                                    Authorized User access credentials and requesting POSH to issue replacement access credentials for the new Authorized User. 
                                    Licensee agrees not to share a departing Authorized User’s access credentials with others.  
                                </li>
                                <li>
                                    <span class="text-bold">B. Restrictions:</span> 
                                    Except as otherwise provided in this Agreement or authorized by POSH, 
                                    Licensee will use only current versions of the Service and will not (and will require its 
                                    Authorized Users to agree that they will not): (i) use or reproduce the Service or 
                                    Licensed Materials (as defined below) or allow use of the Service or the Licensed Materials by anyone except the Authorized Users; 
                                    (ii) decompile, disassemble, reverse-engineer, or otherwise attempt to discover any source code or underlying algorithms in the Service; 
                                    (iii) modify or create derivative works based on the Service, 
                                    provided that nothing herein will prevent Licensee from creating customized versions of template materials for its internal use in the conduct of its business​​​​​​; 
                                    (iv) rent, lease, sell, or otherwise transfer or distribute copies of the of Servic​​​​e to third parties; 
                                    (v) use the Service in the operation of a service bureau or otherwise directly or indirectly use the Service to generate revenue; 
                                    (vi) introduce a virus or malicious or damaging code into the Service or otherwise take any 
                                    action that would have the effect of disrupting the Service or interfering with any other individual or entity’s use of the Service; 
                                    (vii) circumvent any security measures in the Service; (viii) use the Services in any manner that infringes any patent, copyright, trademark, 
                                    or other proprietary or privacy right of any third party; or (ix) use the Service in violation of any applicable laws.  
                                    For the avoidance of doubt, Licensee may modify the POSH materials they have access for purposes of 
                                    customizing those materials to meet the needs of its individual business and to operate its individual business.  
                                    However, those materials will not be shared with any business or individual who does not work for Licensee.  
                                </li>
                                <li>
                                    <span class="text-bold">C. Ownership:</span> 
                                    Licensee acknowledges and agrees that the Service and all documents, 
                                    templates and other materials (including materials of general applicability, such as templates, checklists and other materials, 
                                    that POSH develops in connection with the provision of the Service to Licensee) (“Licensed Materials”) 
                                    available through the Service are licensed and not sold to Licensee or its Authorized Users. 
                                    POSH owns and retains all right, title, and interest in the Site, the Service and all Licensed Materials and any related patent, 
                                    trade secret, copyright, trademark, and other intellectual property rights therein. 
                                    Licensee and each Authorized User is authorized to use the Licensed Materials for its internal business purposes only, 
                                    which may include its dealings with third parties, but not for the benefit of third-parties. 
                                    Licensee will not (and will require its Licensed Franchisees and its and their Authorized Users to agree that they will not) 
                                    remove any proprietary notices or labels from the Licensed Materials other than as necessary to use the Licensed Materials in the Licensee’s business.   
                                </li>
                                <li>
                                    <span class="text-bold">D. Feedback: </span>
                                    Licensee and Authorized Users may, from time to time, provide suggestions, 
                                    recommendations or other feedback provided relating to the Services and any features, 
                                    functionality and/or materials available via the Service (“Feedback”). 
                                    Licensee and Authorized Users are not obligated to provide Feedback to POSH; however, 
                                    if Licensee or any Authorized User does provide such Feedback, 
                                    POSH is entitled to use such Feedback and/or incorporate it into the Service or any 
                                    components thereof without restriction and without any legal or financial obligation to Licensee or any Authorized User.​​​​​
                                </li>
                                <li>
                                    <span class="text-bold">E. No Assignment:</span>
                                    Neither Licensee nor any Authorized User may transfer or assign any right or 
                                    obligation under this Agreement without the prior written consent of POSH; provided, however, 
                                    that POSH will not unreasonably withhold or delay its approval. 
                                </li>
                            </ol>
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">3. Equipment and Communications Capabilities: </span>
                            The Service may require adequate equipment, software, 
                            internet browsers and communications capabilities in order to use the Service and the Licensed Materials. 
                            Licensee is responsible for providing and maintaining any technology necessary to use the Service and the Licensed Materials. 
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">4. Confidentiality of Proprietary Information: </span>
                            The Receiving Party (defined below) agrees that it will:
                            (a) hold in strict confidence and not disclose to any third party the Proprietary Information of the Disclosing Party (each defined below), except as: 
                            pre-approved in writing by the Disclosing Party; or required by law or legal process, 
                            after prior written notice to the Disclosing Party (where permitted by law); 
                            (b) only permit access to the Proprietary Information of the Disclosing Party to those of its personnel 
                            who have a need to know and are fully made aware of the terms of this Section 4 and are subject to 
                            non-disclosure obligations and limitations on use consistent with the terms of this Section 4; 
                            (c) be responsible to the Disclosing Party for its personnel's use and disclosure of the 
                            Proprietary Information provided to the personnel and by the Receiving Party; 
                            (d) only use Proprietary Information that it receives to carry out the purposes of this Agreement and for no other purpose whatsoever; and 
                            (e) use at least the same degree of care it would use to protect its own Proprietary Information of like importance, 
                            but in no event less than a reasonable degree of care, including maintaining the information 
                            security standards for the Proprietary Information required by this Agreement. 
                            Neither Party will communicate any information to the other Party in violation of the proprietary rights of any third party. 
                            For purposes of this paragraph: 
                            (i) “Disclosing Party” means the Party (including, as to Licensee, 
                            its Authorized Users) disclosing Proprietary Information hereunder, whether the disclosure is directly from the Disclosin
                            g Party or through the Disclosing Party's personnel; 
                            (ii) “Receiving Party” means the Party (including, as to Licensee, its Licensed Franchisees) receiving Proprietary Information hereunder; and 
                            (iii) “Proprietary Information” means all non-public, confidential, or proprietary information, materials, 
                            data and trade secrets disclosed by or on behalf of a Party before, on, or after the Effective Date, regardless of whether in oral, 
                            written or electronic form and regardless of whether or not the information is specifically identified as “confidential” and 
                            confidential Proprietary Information does not include any information that 
                            (a) was in the Receiving Party's possession before being disclosed to it by on behalf of the Disclosing Party, as demonstrated by the Receiving Party's records; 
                            (b) is or becomes a matter of public knowledge through no fault of the Receiving Party; 
                            (c) is rightfully received by the Receiving Party from a third party without a duty of confidentiality; or 
                            (d) is independently developed by the Receiving Party without use of the Disclosing 
                            Party’s Proprietary Information, as demonstrated by the Receiving Party’s records. 
                            For the sake of clarity, these exclusions do not apply to Proprietary Information that is the subject of an unauthorized disclosure, 
                            and the Parties’ obligations to maintain the confidentiality and security of Proprietary Information will apply to prevent further unauthorized disclosure. 
                            For the avoidance of doubt, the Licensed Materials are the Proprietary Information of. 
                            A Receiving Party will not be in violation of this Section 4 if it discloses Proprietary Information in response to a court order, 
                            a subpoena or a government demand, provided that , the Receiving Party will promptly notify the 
                            Disclosing Party of such disclosure requirement (to the extent legally permissible), 
                            will only disclose that Proprietary Information that counsel advises is responsive to the demand and will reasonably cooperate with the 
                            Disclosing Party (at the Disclosing Party’s expense) to see a protective order to preserve the confidentiality of the Proprietary Information at issue.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">5. Security Procedures: </span>
                            POSH shall maintain data back-up procedures, 
                            and information security systems and processes (collectively, the “Security Systems”) designed to protect the 
                            Proprietary Information and the Personal Information (as defined below) from being lost, stolen, modified, disclosed to, 
                            or accessed by any third party or any POSH personnel who are not authorized to access such information in order to perform 
                            POSH’s obligations under this Agreement (except in accordance with this Agreement). 
                            The Security Systems shall be consistent with applicable laws and with industry 
                            standard practices for similar parties dealing with comparable Proprietary Information. 
                            POSH will reasonably monitor, evaluate and adjust the Security Systems in 
                            response to relevant changes in technology and internal and external threats to information security.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">6. Licensee Security Obligations:</span> 
                            Licensee is responsible for controlling access to and maintaining 
                            the confidentiality of the details related to the access credentials Authorized Users​​​​ receive from POSH. 
                            Licensee is solely responsible for all activities that occur under its account or any access credentials of Licensee or its Authorized Users’. 
                            Licensee will notify POSH promptly if it becomes aware of any unauthorized use of 
                            Authorized User’s access credentials or the Service and will require each 
                            Licensed Franchisee to immediately report the same to Licensee to enable such notification.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">7. Data Privacy</span>: 
                            For the purposes of this Agreement, 
                            “Personal Information” means information that POSH processes in performance of this 
                            Agreement about an identifiable individual or household that is subject to any standards, protections, 
                            or limitations under applicable data privacy/security laws, including but not limited to the 
                            California Consumer Privacy Act of 2018 (CCPA), as amended and its implementing regulations and guidelines. 
                            The only Personal Information POSH will collect from the Authorized Users in connection with the 
                            Services are the names and email addresses (and employee/member identification number if applicable) of 
                            Authorized Users of the Service and tracking log-ons and use of the Licensed Materials within the Service. 
                            POSH will not share, sell, assign or lease any Personal Information to any third party for commercial purposes.  
                            POSH will not process or retain Personal Information outside of the direct business relationship between the Parties.  
                            POSH will also not combine Personal Information received pursuant to this Agreement with other information from any other source, 
                            except as needed to provide the Service to Licensee (Licensee’s franchisor, if applicable) and Authorized Users.  
                            POSH confirms that it understands these restrictions and will comply with them. 
                            POSH shall immediately inform Licensee if it can no longer meet its obligations under applicable data privacy/security laws. 
                            All Personal Information will only be used, processed, accessed, maintained, collected, modified, disclosed, 
                            or stored by POSH in the U.S. and solely for purposes of fulfilling POSH’s obligations pursuant to the Agreement, 
                            specifically, provision of the Site, access to the Site, and Service, including webinars and communications to Licensee and Authorized Users, 
                            as directed by Licensee, and as otherwise required or permitted by Licensee or the Authorized User. 
                            In connection with the performance of the Services, POSH will not access Licensee’s systems.  
                            The Parties agree that Licensee must take reasonable and appropriate steps to ensure that POSH processes 
                            Personal Information consistent with its obligations under applicable laws.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">8. Third-Party Access:</span> 
                            POSH will be responsible for the compliance of any subcontractor, agent, vendor or auditor for POSH, 
                            that processes or retains Personal Information on POSH’s behalf with standards and limitations consistent with those set forth in this Agreement. 
                            For the avoidance of doubt, Licensee understands and agrees that POSH’s hosting provider will process,
                            maintain and store certain Personal Information as and to the extent necessary to provide hosting services for the benefit of POSH; 
                            provided that the hosting provider is subject to use limitations and security requirements consistent applicable law. 
                            All Personal Information will only be used, processed, accessed, maintained, collected, modified, disclosed, or stored by POSH in the U.S. 
                            and solely for purposes of fulfilling POSH’s obligations pursuant to the Agreement and as otherwise required or permitted by Licensee or the Authorized User.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">9. Data Retention:  </span>
                            POSH will promptly delete, amend, produce or take other required actions with respect to Personal Information, 
                            or enable Licensee to directly take such actions, as required for compliance with applicable data privacy/security laws.  
                            POSH will also notify any of its own subcontractors, agents, contractors, 
                            vendors and auditors to promptly take such action with respect to Personal Information they maintain, 
                            in accordance with applicable data privacy/security laws.  
                            POSH will only process Personal Information during the term of the Agreement.  
                            After termination for any reason, POSH will at the choice of Licensee, delete all Personal Information and certify to Licensee that it has done so, 
                            or return to Licensee all Personal Information and delete all copies and data compilations, 
                            and other works derived therefrom that allow identification of any individual.  
                            POSH will likewise require any subcontractors, agents, contractors, vendors or auditors to return or destroy all of Licensee and Licensed Franchisee’s Personal Information. 
                            POSH will complete these obligations as promptly as possible, but not later than 60 days following termination of this Agreement. 
                            Notwithstanding the foregoing, POSH may retain any Confidential Information to the extent required to do so by applicable law or professional standards, 
                            subject to continued compliance with the confidentiality obligations set forth herein. 
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">10. Licensee Responsibilities:</span>
                            Licensee shall be solely responsible for, among other things: 
                            (i) making all determinations about when and how to use the Service and the Licensed Materials and whether the same are appropriate for use in specific circumstances; 
                            (ii) designating one or more individuals who possess suitable skill, knowledge, 
                            authority and/or experience to coordinate with POSH on the Licensee’s and its Authorized Users’ access to the Service and the Licensed Materials; 
                            (iii) evaluating the adequacy and results of the Service and the Licensed Materials; 
                            (iv) accepting responsibility for its implementation and use of the Licensed Materials and the information it derives from its use of the Service; and 
                            (v) establishing and maintaining internal controls to govern its use of the Service and the Licensed Materials. 
                            With respect to the data and information provided by Licensee to the Service, Licensee shall have all rights required to provide such data and information,
                            and shall do so only in accordance with applicable law and with any procedures agreed upon in writing. 
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">11. Termination/Suspension: </span>
                            POSH may terminate this Agreement as to Licensee and/or any Authorized User at any time during the Term, 
                            for convenience and without cause, upon sixty (60) days’ prior written notice to Licensee. 
                            Further, either Party may terminate this Agreement for cause if the other Party commits a material breach of the 
                            Agreement and fails to cure such material breach within ten (10) business days after the non-breaching Party provides written notice of such breach. 
                            POSH may suspend Licensee’s and any Authorized User’s access to the Service and/or terminate the Agreement immediately upon written notice to Licensee if, 
                            (i) Licensee or any Authorized User that misappropriates or infringes any of the Licensed Materials or other POSH intellectual property; and/or 
                            (ii) Licensee or any Authorized User uses the Service or the Licensed Materials in a manner that threatens to disrupt the operation of the 
                            Service (e.g., through a denial of service attack), create liability for POSH or compromise the security of the Service or other customer accounts.  
                            POSH will provide advance notice of any such suspension if it is reasonably practicable to do so under the circumstances.  
                            If it is not reasonably practicable to provide advance notice, POSH will provide Licensee with notice as soon after the suspension as possible.  
                            POSH will limit its suspension to the scope and duration necessary to address the issue giving rise to the suspension.  
                            The termination of access under this Agreement with respect to a specific Authorized User will not impact the continued effect of 
                            this Agreement as to Licensee or the other Authorized Users unless POSH terminates the Agreement as a whole with Licensee. 
                            Upon termination of the Agreement, all rights and licenses granted hereunder shall terminate and 
                            Licensee and its Authorized Users will immediately cease all use of the Service and the Licensed Materials.    
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">12. Mutual Warranties:</span>
                            Each Party hereby represents and warrants that: 
                            (i) it is duly organized and validly existing under the laws of its state of formation and has full corporate power and authority to enter into this 
                            Agreement and to carry out the provisions hereof; 
                            (ii) this Agreement is a legal and valid obligation binding upon it and enforceable in accordance with its terms; and 
                            (iii) the execution, delivery, and performance of this Agreement does not conflict with any agreement, instrument, or understanding, 
                            oral or written, to which it may be a party or by which it may be bound, and does not violate any law or regulation of any court, governmental body, 
                            or administrative or other agency having jurisdiction over it (including any applicable import or export regulation).  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">13. Disclaimer of Warranties: </span>
                            Except as otherwise provided herein, POSH makes no representations or warranties of any kind: 
                            (i) that the Services, including without limitation the operation of any Third Party Materials embedded in or to which the Service is linked, 
                            will be uninterrupted, error-free, or free of program limitations; or 
                            (ii) that the Service, including without limitation the Site, the Licensed Materials and any Third Party Materials, are free of defects. 
                            To the maximum extent permitted by law, POSH disclaims all representations and warranties of all kinds, whether express, implied, 
                            or statutory, in connection with this Agreement, the Site, the Service, the Licensed Materials and the Third Party Materials, 
                            including any implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">14. No Legal Relationship: </span>
                            The Licensed Materials and other content available via the Service provide general information and tools only, 
                            and neither POSH nor any of POSH’s officers, directors, administrators, managers, employees, agents, 
                            representatives or contractors is providing any legal, tax, accounting, investment or other advice in any fiduciary capacity. 
                            The use of the Service or the Licensed Materials by Licensee or any Authorized User does not create an attorney-client 
                            relationship between POSH and the applicable entity or individual. Licensee and each Authorized User is solely responsible for 
                            its determination of how to use the Service and the Licensed Materials and for any and all content and information they incorporate into the Licensed Materials. 
                            If legal or other professional or fiduciary advice is required, then Licensee or the applicable Licensed Franchisee should retain the services of a qualified legal professional. 
                            For the avoidance of doubt, nothing in this Agreement modifies the terms of any engagement letter between Licensee and Polsinelli P.C.  
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">15. Indemnification: </span>
                            Each Party (the "Indemnifying Party") will defend the other Party, its respective shareholders, officers, directors, 
                            administrators, managers, employees, agents, representatives and contractors, successors and assigns (each, an "Indemnified Party") 
                            from and against any and all third party claims and will indemnify and hold the 
                            Indemnified Parties harmless from all losses arising out of or in connection with 
                            (i) the Indemnifying Party’s breach of any of its representations or warranties under this Agreement; and 
                            (ii) the Indemnifying Party's actual or alleged infringement of any patent, copyright, trademark, or other proprietary or 
                            intellectual property right by any goods, services, or other performance delivered by the Indemnifying Party pursuant to this Agreement. 
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">16. Trademark License; Promotion: </span>
                            POSH and Licensee hereby grant to each other a personal, limited, non-exclusive, revocable, non-transferable, 
                            royalty-free license to use the specific trademarks and logos delivered by email by 
                            each Party to the other only as and to the extent necessary to perform its obligations under this Agreement. 
                            Each Party represents and warrants that it possesses all rights necessary for the granting of such license. 
                            All marketing, promotion and use of the Service by Licensee shall be in accordance with the marketing materials and procedures supplied by POSH, 
                            with any changes subject to the prior written approval of POSH. 
                            Licensee shall not market the Service in conjunction with any other service or product. 
                            Licensee shall permit POSH to periodically review Licensee’s marketing and promotion of the Service
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">17. Limitation of ​​Liability: </span>
                            POSH SHALL NOT BE LIABLE TO LICENSEE OR ANY AUTHORIZED USER UNDER THIS AGREEMENT FOR 
                            (I) ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING ANY LOSS OF INCOME OR PROFITS, 
                            EVEN IF POSH HAS BEEN ADVISED OF, OR HAD REASON TO KNOW OF, THE POSSIBILITY OF SUCH DAMAGES; OR 
                            (II) ANY AMOUNT PER CLAIM GREATER THAN (A) $15,000.00 USD; 
                            OR (B) THE AMOUNT OF FEES PAID BY LICENSEE TO POSH DURING THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE CLAIM GIVING RISE TO SUCH LIABILITY.  
                            The foregoing limitations do not apply to (a) claims arising out of Licensee’s or any 
                            Authorized User’s violation or misappropriation of POSH’s intellectual property rights in or to the Service or the Licensed Materials; 
                            (b) claims arising out of Licensee’s or any Authorized User’s use of the Service or the 
                            Licensed Materials in violation of applicable law or for purposes that go beyond the rights and licenses expressly granted herein; 
                            (c) Licensee’s indemnification obligations; or (d) either party’s gross negligence or willful misconduct.  
                            Further, the foregoing limitation of liability does not in any way modify any separate obligations set forth in an engagement letter between Polsinelli P.C. and Licensee. 
                        </p>
                        <p class="text-normal">
                            <span class="text-bold">18. Miscellaneous: </span>
                            <ol type="A" class="text-normal">
                                <li>
                                    <span class="text-bold">A. Entire Agreement; Modification: </span>
                                    This Agreement constitutes the entire agreement between the Parties concerning the 
                                    subject matter hereof and supersedes all written or oral, prior or contemporaneous agreements or understandings with 
                                    respect thereto other than the terms of any Subscription Agreement entered into by and between Polsinelli Online Solutions, 
                                    LLC dba Polsinelli Online Solutions for Home Care (“POSH”) and a participant organization which signed such agreement. 
                                    No course of dealing or usage of trade will be used to modify the terms hereof. 
                                    No modification, extension, or waiver of or under this Agreement will be valid unless made in writing and signed by 
                                    an authorized representative of the Party sought to be charged therewith. No written waiver will constitute, or be construed as, 
                                    a waiver of any other obligation or condition of this Agreement. 
                                </li>
                                <li>
                                    <span class="text-bold">B. Counterparts: </span>
                                    This Agreement and any written amendments hereto may be executed in any number of separate counterparts, 
                                    each of which will be deemed to be an original, and all of which counterparts, taken together, will constitute one and the same agreement. 
                                    Signatures to this Agreement (or to any document signed in connection with this Agreement) 
                                    transmitted in a commonly accepted electronic format that reproduces an image of the actual 
                                    executed signature page will be deemed a binding original and will have the same legal effect, validity, 
                                    and enforceability as a manually executed counterpart of the document to the extent and as provided for in the 
                                    Federal Electronic Signatures in Global and National Commerce Act and the applicable state law based on the 
                                    Uniform Electronic Transactions Act. In no event will POSH be obligated hereunder unless and until this Agreement has been delivered to and accepted by POSH.  
                                </li>
                                <li>
                                    <span class="text-bold">C. Unenforceability:</span> 
                                    If any provision of this Agreement is found by a court of competent jurisdiction or an arbitrator to be unenforceable or invalid,
                                    such unenforceability or invalidity will not render this Agreement unenforceable or invalid as a whole. 
                                </li>
                                <li>
                                    <span class="text-bold">D. Notices: </span>
                                    With respect to notices permitted or required under this Agreement, such notices must be in writing and delivered by email, personal delivery, by certified mail or by overnight carrier mail, 
                                    return receipt requested, (unless another method is specified within the Agreement) at the addresses indicated in the Order Form of this Agreement, and shall be deemed given upon receipt. 
                                </li>
                                <li>
                                    <span class="text-bold">E. Relationship of the Parties: </span>
                                    The Parties are independent contractors. 
                                    This Agreement does not create nor is it intended to create a partnership, franchise, joint venture, agency, 
                                    fiduciary or employment relationship between the Parties. 
                                    There are no third-party beneficiaries to this Agreement. 
                                </li>
                                <li>
                                    <span class="text-bold">F. Survival: </span>
                                    The terms of Sections 2(c), 4, 5, 7, 8, 9,  11 and 13 through 18 of these Standard Terms and Conditions and any and all other provisions, 
                                    promises and warranties contained herein that by their nature or effect are required or intended to be observed, 
                                    kept or performed after termination of this Agreement will survive the expiration or termination of this 
                                    Agreement and remain binding upon and for the benefit of the Parties. 
                                </li>
                                <li>
                                    <span class="text-bold">G. Governing Law and Jurisdiction: </span>
                                    This Agreement will be governed by and construed in accordance with the laws of the State of ​Delaware without reference to conflict of laws principles. 
                                    Any legal action brought under or in conjunction with this Agreement will be brought in a federal or state court of appropriate jurisdiction in Wilmington, 
                                    Delaware and venue will be proper in that court. 
                                    Each Party hereby consents to the jurisdiction of such courts and waives its right to claim that such forum is not convenient or proper.  
                                </li>
                            </ol>
                        </p>
                    </section>
                </div>
                <div v-if="!acceptedTerms && isSubscriberUser && !isProxyLoggedIn" class="buttons-container">
                    <button class="btn secondary-btn" @click="rejectTerms">Reject</button>
                    <button class="btn primary-btn" @click="acceptTerms">Accept</button>
                </div>
                <div v-if="acceptedTerms || isAdministratorUser || !isLoggedIn || isProxyLoggedIn" class="buttons-container">
                    <button class="btn secondary-btn" @click="closeTerms">Close</button>
                </div>
            </div>
        </section>
        <footer class="terms-footer">
            <div>
                <p class="text-bold">Terms of Service</p>
                <p class="text-normal">
                    By using this site, your agree to our <a href="#"  @click="showTermsAndCondtions = true">Terms of Service</a>
                </p>
            </div>
            <!-- <button
                v-if="!acceptedTerms && isSubscriberUser"
                class="btn secondary-btn view-terms-btn"
                @click="showTermsAndCondtions = true"
            >
                View To Accept
            </button> -->
        </footer>
    </div>
</template>

<style lang="scss" scoped>
.terms-and-conditions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    place-items: center;
    top: 0;
    position: fixed;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(244, 244, 244, 0.5);
    backdrop-filter: blur(5px);
}
.terms-modal {
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 1200px;
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    box-shadow: 2px 2px 24px 0 rgba(0, 0, 0, 0.15);
}

.terms-banner {
    background-image: url('/assets/images/hero/hero-banner-v1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center -66px;
}
.text-container {
    padding: 40px;
}
.terms-body {
    padding: 20px;
    text-align: justify;
}
.terms-body p {
    margin-bottom: 1rem;
}
.terms-body ol {
    padding-left: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: black;
}
.terms-body ol li {
    margin-bottom: 1rem;
}
.buttons-container {
    /* position: sticky; */
    bottom: 0;
    display: flex;
    gap: 5px;
    justify-content:flex-end;
    padding: 20px;
    background-color: #F8F8F8;
    width: 100%;
    margin-top: 20px;
}
.terms-footer {
    display: flex;
    gap: 1rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 2rem;
    
}
.terms-footer  p {
    margin-right:  10px;
}
.text-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5rem;
    color: black;
}
.text-normal {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.15rem;
    color: black;
}

</style>