<template>
    <div class="modal">
        <div class="modal-card">
            <div class="request-demo-form">
                <div class="header">
                    <!-- <h2>Request For Information & Service</h2> -->
                    <h3 class="active">Request a Demo</h3>
                    <div class="close-button" @click="$emit('close')">
                        <img src="/assets/icons/close-modal.svg" alt="" />
                    </div>
                </div>
                <RequestDemoForm @success="closeModal" />
            </div>
        </div>
    </div>
</template>

<script setup>
import RequestDemoForm from "../inputs/RequestDemoForm.vue";

const emits = defineEmits(["close"]);

const closeModal = () => {
    emits("close");
};

</script>

<style lang="scss" scoped>
div.error-text {
    font-size: 0.7rem;
    color: #9F3037;
    margin-bottom: 5px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: 99999;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-card {
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 100%;
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
}

.close-button {
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 5px;
    background: #313e47;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        filter: invert(1);
        max-width: 100%;
        height: auto;
    }
}

.btn:disabled {
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .modal-card {
        padding: 10px;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1rem;
    }
}
</style>
