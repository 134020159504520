import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
// Get the current host
const currentHost = location.host;

const host = 
        currentHost === 'localhost'
        ? 'http://localhost:8004/api'
        : currentHost === 'polsinelli-web-app.azurewebsites.net'
        ? 'https://polsinelli-web-app.azurewebsites.net/api'
        : currentHost === 'polsinelli-dev.azurewebsites.net'
        ? 'https://polsinelli-dev.azurewebsites.net/api'
        : currentHost === 'polsinelli-web-posh.azurewebsites.net'
        ? 'https://polsinelli-web-posh.azurewebsites.net/api'
        : currentHost === 'posse.azurewebsites.net'
        ? 'https://posse.azurewebsites.net/api'
        : currentHost === 'onlinedev.polsinelli.com'
        ? 'https://onlinedev.polsinelli.com/api'
        : currentHost === 'online.polsinelli.com'
        ? 'https://online.polsinelli.com/api'
        : 'http://localhost:8004/api';
const apiClient = axios.create({
    baseURL: host,
});

// Add a request interceptor
apiClient.interceptors.request.use(
    function (config) {
        // Get the token from localStorage
        const token = localStorage.getItem("token");
        // If the token exists, add it to the Authorization header
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    function (error) {
        if (error.response && error.response.status === 401) {
            router.push("/login");
        }
        // Handle request error
        return Promise.reject(error);
    }
);

export default apiClient;
