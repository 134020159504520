import { defineStore } from "pinia";
import api from "../services/client";
import { db } from "../db";

export const useOrgStore = defineStore("orgStore", {
    state: () => ({
        currentOrgDetails: null,
        orgGroups: [],
        orgGroupsArchived: [],
        orgGroupsSearchResults: [],
        orgData: [],
        orgArchived: [],
        allOrgs: [],
        allOrgGroups: JSON.parse(localStorage.getItem("allOrgGroups")) || [],
        searchResults: [],
        selectedOrgs: [],
        orgsInlistOfGroups: null,
        organizations: [],
        organizationGroupsWithIds: [],
        totalOrganisationGroupCounts:
            JSON.parse(localStorage.getItem("totalOrganisationGroupCounts")) ||
            [],
        selectedProprietaryEmail: [],
        eventTypes: [],
        covidNineteen: [],
        contentTypes: [],
        employmentLifecycles: [],
        jurisdictions: [],
        areasOfLaws: [],
        industries: [],
        organisationTypes: [],
        franchises: [],
        proprietaries: [],
        typeOfContents: [],
        test: [],
        orgPreferences: [],
        selectedLanguage: "English (USA) Default",
        selectedEmailPref: "Monthly",
        mediaFiles: [],
        modules: [],
        selectedModules: [],
        existingOrgs: null,
        organisation_groups: null,
        updateOrgs: [],
        updateSubscriptions: [],
        showPop: false,
        popMessage: null,
        responseMessage: {
            type: null,
            text: "",
        },
        isLoading: {
            orgData: false,
            orgGroups: false,
            orgArchived: false,
            orgGroupsArchived: false,
            searchResults: false,
            groupsSearchResults: false,
            allOrgGroups: false,
            allOrgs: false,
            singleOrg: false,
            orgPreferences: false,
        },
        isUpdated: {
            orgData: false,
            orgPreferences: false,
            orgEmailPreferences: false,
            orgModules: false,
            orgGroupsArchived: false,
            orgGroups: false,
            searchResults: false,
        },
        metadata: {
            event_types: [],
            content_type: [],
            industry: [],
            jurisdiction: [],
            organisation_type: [],
            covid19: [],
            area_of_law: [],
            employment_lifecycle: [],
            type_of_content: [],
            proprietary: [],
        },
        preferenceMetadata: {
            event_types: [],
            content_type: [],
            industry: [],
            jurisdiction: [],
            organisation_type: [],
            covid19: [],
            area_of_law: [],
            employment_lifecycle: [],
            type_of_content: [],
            proprietary: [],
        },
        module: {
            module_id: [],
            preference: [],
        },
        archiveStatus: false,
        searchPage: 1,
        pageSize: 25,
        hasMore: {
            orgGroups: false,
            orgGroupsArchived: false,
            orgs: false,
            archivedOrgs: false,
            searchResults: false,
            groupsSearchResults: false,
        },
        totalPages: 0,
    }),
    getters: {
        getCurrentUserOrg: (state) => {
            return state?.currentOrgDetails;
        },
        getActiveOrgs: (state) => {
            return state?.orgData;
        },
        getArchivedOrgs: (state) => {
            return state?.orgArchived;
        },

        getOrgGroupsList: (state) => {
            return state?.orgGroups;
        },

        getArchivedOrgGroupsList: (state) => {
            return state?.orgGroupsArchived;
        },

        getOrgsInlistOfGroups: (state) => {
            return state?.orgsInlistOfGroups;
        },

        getSelectedLanguage: (state) => {
            return state?.selectedLanguage;
        },
        getSelectedEmailPref: (state) => {
            return state?.selectedEmailPref;
        },

        getAllFranchises: (state) => {
            return state?.franchises;
        },

        getAllOrganisationTypes: (state) => {
            return state?.organisationTypes;
        },

        filteredModuleOrganisations: (state) => (organizationId) => {
            return state.modules.filter((module) => {
                return module.organisation.some(
                    (org) => org.id === organizationId
                );
            });
        },

        getSelectedModules: (state) => {
            return state?.selectedModules;
        },

        getSelectedEventTypes: (state) => {
            return state?.metadata.event_types;
        },

        getSelectedEventTypesEmail: (state) => {
            return state?.preferenceMetadata.event_types;
        },

        getSelectedIndustry: (state) => {
            return state?.metadata.industry;
        },

        getSelectedIndustryEmail: (state) => {
            return state?.preferenceMetadata.industry;
        },

        getSelectedAreasOfLaws: (state) => {
            return state?.metadata.area_of_law;
        },

        getSelectedAreasOfLawsEmail: (state) => {
            return state?.preferenceMetadata.area_of_law;
        },

        getSelectedJurisdictions: (state) => {
            return state?.metadata.jurisdiction;
        },

        getSelectedJurisdictionsEmail: (state) => {
            return state?.preferenceMetadata.jurisdiction;
        },

        getSelectedCovidNineteen: (state) => {
            return state?.metadata.covid19;
        },

        getSelectedCovidNineteenEmail: (state) => {
            return state?.preferenceMetadata.covid19;
        },

        getSelectedEmploymentLifecycles: (state) => {
            return state?.metadata.employment_lifecycle;
        },

        getSelectedEmploymentLifecyclesEmail: (state) => {
            return state?.preferenceMetadata.employment_lifecycle;
        },

        getSelectedTypeOfContents: (state) => {
            return state?.metadata.type_of_content;
        },

        getSelectedTypeOfContentsEmail: (state) => {
            return state?.preferenceMetadata.type_of_content;
        },

        getSelectedContentTypes: (state) => {
            return state?.metadata.content_type;
        },

        getSelectedContentTypesEmail: (state) => {
            return state?.preferenceMetadata.content_type;
        },

        getSelectedOrganisationTypes: (state) => {
            return state?.metadata.organisation_type;
        },

        getSelectedOrganisationTypesEmail: (state) => {
            return state?.preferenceMetadata.organisation_type;
        },

        getSelectedProprietary: (state) => {
            return state?.metadata.proprietary;
        },

        getSelectedProprietaryEmail: (state) => {
            return state?.preferenceMetadata.proprietary;
        },

        getSearchResults: (state) => {
            return state?.orgGroupsSearchResults;
        },

        getAllActiveOrgs: (state) => {
            return state?.allOrgs;
        },

        getAllActiveOrgGroups: (state) => {
            return state?.allOrgGroups;
        },

    },
    actions: {
        async processData() {
            // Process data and populate organizationGroupsWithIds
            this.organizationGroupsWithIds = await this.orgData?.map((org) => {
                if (org.organisation_group) {
                    const modifiedOrgGroup = org.organisation_group.map(
                        (group) => {
                            const { pivot } = group;
                            const organisation_group_id =
                                pivot.organisation_group_id;
                            const organisation_id = pivot.organisation_id;

                            return {
                                ...group,
                                organisation_group_id,
                                organisation_id,
                            };
                        }
                    );

                    // Count unique organisation_ids for each organisation_group_id
                    const pivotCounts = modifiedOrgGroup.reduce(
                        (counts, group) => {
                            const { organisation_group_id, organisation_id } =
                                group;

                            if (!counts[organisation_group_id]) {
                                counts[organisation_group_id] = {
                                    count: 0,
                                    organisation_ids: new Set(),
                                };
                            }

                            counts[organisation_group_id].count++;
                            counts[organisation_group_id].organisation_ids.add(
                                organisation_id
                            );
                            return counts;
                        },
                        {}
                    );

                    return {
                        ...org,
                        organisation_group: modifiedOrgGroup,
                        organisationGroupCounts: pivotCounts,
                    };
                } else {
                    return org; // Keep the original org if organisation_group is null/empty
                }
            });

            // Create an object to store the organisation_ids associated with each organisation_group_id
            this.totalOrganisationGroupIds = {};

            this.organizationGroupsWithIds.forEach((org) => {
                for (const group_id in org.organisationGroupCounts) {
                    if (!this.totalOrganisationGroupIds[group_id]) {
                        this.totalOrganisationGroupIds[group_id] = new Set();
                    }
                    org.organisationGroupCounts[
                        group_id
                    ].organisation_ids.forEach((id) => {
                        this.totalOrganisationGroupIds[group_id].add(id);
                    });
                }
            });

            const totalCounts = {};
            this.organizationGroupsWithIds.forEach((org) => {
                for (const group_id in org.organisationGroupCounts) {
                    if (!totalCounts[group_id]) {
                        totalCounts[group_id] = 0;
                    }
                    totalCounts[group_id] +=
                        org.organisationGroupCounts[group_id].count;
                }
            });

            // Update the totalOrganisationGroupCounts property
            this.totalOrganisationGroupCounts = totalCounts;

            // Save the total counts to localStorage
            localStorage.setItem(
                "totalOrganisationGroupCounts",
                JSON.stringify(totalCounts)
            );
        },

        setSelectedProprietaryEmail(selectedProprietaryEmail) {
            this.preferenceMetadata.proprietary = selectedProprietaryEmail;
        },

        fetchOrgById(orgId) {
            let config = {
                method: "POST",
                url: "/organisation/single",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: {
                    id: orgId,
                },
            };
            this.isLoading.singleOrg = true;
            return api(config).then((response) => {
                this.currentOrgDetails = response.data.data.at(0);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.isLoading.singleOrg = false;
            });
        },

        /**
         * Fetches all organisations with the specified options.
         * @param {Object} options - The options for fetching organisations.
         * @param {boolean} options.archived - Whether to include archived organisations. Default is false.
         * @param {string} options.status - The status of the organisations to fetch. Default is "active".
         * @returns {Promise<Array>} - A promise that resolves to an array of organisations.
         */
        async fetchAllOrganisations(options = { archived: false, status: "active" }) {
            let config = {
                method: "get",
                url: "/organisations",
                //params: options,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            try {
                this.isLoading.allOrgs = true;
                this.allOrgs = await api(config).then((response) => {
                    this.isLoading.allOrgs = false;
                    return response.data.data;
                });
            } catch (error) {
                console.error(error);
                this.isLoading.allOrgs = false;
            }
        },

        /**
         * Fetches all organization groups.
         * @param {Object} options - Options for fetching organization groups.
         * @param {boolean} options.archived - Flag indicating whether to include archived groups.
         * @param {string} options.title - Title of the groups to filter by.
         * @returns {Promise} - A promise that resolves to the fetched organization groups.
         */
        async fetchAllOrgGroups(options = { archived: false, title: '' }) {
            const cachedData = localStorage.getItem('allOrgGroups');
            if (cachedData && cachedData.length > 0 && !this.isUpdated.orgGroups) {
                this.allOrgGroups = JSON.parse(cachedData);
                return;
            }
            let config = {
                method: "get",
                url: "/organisation/group/fetch/all",
                params: options,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            this.isLoading.allOrgGroups = true;
            return await api(config).then((response) => {
                this.allOrgGroups = response.data.data;
                localStorage.setItem('allOrgGroups', JSON.stringify(response.data.data));
                this.isUpdated.orgGroups = false;
            }).catch((error) => {
                console.error(error);
                return error.response;
            }).finally(() => {
                this.isLoading.allOrgGroups = false;
            });
        },

        /**
         * APIC Call:
         * Fetches organisations list from the server else uses cached results if nothing has changed.
         *
         * @param {Object} options - The options for fetching orgs.
         * @param {boolean} options.archived - Indicates whether to fetch archived orgs.
         * @param {number} options.page - The page number for pagination.
         * @param {number} options.per_page - The number of orgs per page.
         * @returns {Promise<void>} - A promise that resolves when the orgs are fetched.
         */
        async getOrgData(options = { archived: false, page: 1, per_page: 25 }) {
            let config = {
                method: "get",
                url: "/organisation/index",
                params: options,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
        
            this.isLoading.orgData = true;
            try {
                const response = await api(config);
                // If it's the first page, replace the data
                if (options.page === 1) {
                    this.orgData = response.data.data;
                } else {
                    // Otherwise, append the new data to the existing data
                    this.orgData = this.orgData.concat(response.data.data);
                }
                this.hasMore.orgs = options.page < response.data.total_pages;
                this.totalPages = response.data.total_pages;
                return response.data.data // return the entries for the current page (Used for Excel export)
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading.orgData = false;
            }
        },
        

        /**
         * APIC Call:
         * Fetches archived organisations list from the server else uses cached results if nothing has changed.
         */
        async getOrgArchived(options = { archived: true, page: 1, per_page: 25 }) {
            let config = {
                method: "get",
                url: "/organisation/index",
                params: options,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            this.isLoading.orgArchived = true;
            try {
                const response = await api(config);
                // If it's the first page, replace the data
                if (options.page === 1) {
                    this.orgArchived = response.data.data;
                } else {
                    // Otherwise, append the new data to the existing data
                    this.orgArchived = this.orgArchived.concat(response.data.data);
                }
                this.hasMore.archivedOrgs = options.page < response.data.total_pages;
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading.orgArchived = false;
            }
        },

        async searchOrgList(organisation = '', archived = false, status = '', page = 1, pageSize = 25) {
            const headers = {
                method: "GET",
                url: "/organisation/index",
                Accept: "application/json",
                params: {
                    organisation: organisation,
                    archived: archived,
                    status: status,
                    page: page,
                    per_page: pageSize,
                }
            };
            this.isLoading.searchResults = true;
            await api(headers)
                .then((res) => {
                    if (page === 1) {
                        this.orgData = res.data.data; // Set orgData to searchResults
                        this.searchResults = res.data.data;
                    } else {
                        this.searchResults = this.searchResults.concat(
                            res.data.data
                        );
                        this.orgData = this.searchResults;
                    }
                    this.hasMore.searchResults =
                        this.searchResults.length < res.data.total;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading.searchResults = false;
                });
        },

        async archiveOrg(organisationId) {
            const archiveId = {
                id: organisationId,
            };
            let config = {
                method: "post",
                url: "/organisation/delete",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: archiveId,
            };
            try {
                await api(config).then((res) => {
                    this.isUpdated.orgData = true;
                    this.getOrgData({ archived: false, page: 1, per_page: 25 });
                    this.getOrgArchived({ archived: true, page: 1, per_page: 25 });
                    // console.log("response", res.status);
                    if (res.status == 200) {
                        (this.responseMessage.type = "success"),
                            (this.responseMessage.text =
                                "Organization Archived");
                    }
                });
            } catch (error) {
                console.error(error);
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.res.status}: ${error.res.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        async unarchiveOrg(orgId) {
            let requestData = {
                id: orgId,
            };

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "/organisation/undelete",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: requestData,
            };

            try {
                await api(config).then((res) => {
                    this.isUpdated.orgData = true;
                    this.getOrgData({ archived: false, page: 1, per_page: 25 });
                    this.getOrgArchived({ archived: true, page: 1, per_page: 25 });
                    if (res.status == 200) {
                        this.responseMessage.type = "success";
                        this.responseMessage.text =
                            "Organization set to Active";
                    }
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        /**
         * API Call:
         * Updates an organisation using the given organisation data.
         * @param {Object} updateOrgsObject updated organisation data
         */
        async updateOrgData(updateOrgsObject) {
            let data = new FormData();
            // console.log('updateOrgsObject',updateOrgsObject);
            data.append("id", updateOrgsObject.id);
            data.append(
                "organisation_type_id",
                updateOrgsObject.organisation_type_id
            );

            if (updateOrgsObject.name) {
                data.append("name", updateOrgsObject.name);
            }
            if (updateOrgsObject.domain) {
                data.append("domain", updateOrgsObject.domain);
            }
            if (updateOrgsObject.franchise_id) {
                data.append("franchise_id", updateOrgsObject.franchise_id);
            }
            if (updateOrgsObject.image) {
                data.append("image", updateOrgsObject.image);
            }
            if (updateOrgsObject.status) {
                data.append("status", updateOrgsObject.status);
            }
            if (updateOrgsObject.internal) {
                data.append("internal", updateOrgsObject.internal);
            }
            if (updateOrgsObject.auto_login) {
                data.append("auto_login", updateOrgsObject.auto_login);
            }
            let config = {
                method: "post",
                url: "/organisation/update",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: data,
            };
            return await api(config)
                .then((res) => {
                    this.isUpdated.orgData = true;
                    this.getOrgData({ archived: false, page: 1, per_page: 25 });
                    if (res.status == 200) {
                        (this.responseMessage.type = "success"),
                            (this.responseMessage.text =
                                "Organization was updated successfully");
                    }
                })
                .catch((error) => {
                    const errorMessages = Object.values(error.response.data.errors).join(" ");
                    this.responseMessage.type = "error";
                    this.responseMessage.text = `Request failed: ${errorMessages}`;
                    setTimeout(() => {
                        this.responseMessage.text = "";
                        this.responseMessage.type = "";
                    }, 3000);
                    console.error(error);
                });
        },

        /**
         * Removes an image associated with an organization.
         * 
         * @param {number} orgId - The ID of the organization.
         * @returns {Promise<void>} - A promise that resolves when the image is successfully removed.
         */
        async removeImage(orgId) {
            let requestData = {
                organisation_id: orgId,
            };
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "/organisation/image/delete",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: requestData,
            };
            try {
                await api(config).then((res) => {
                    this.isUpdated.orgData = true;
                    this.getOrgData();
                    if (res.status == 200) {
                        (this.responseMessage.type = "success"),
                            (this.responseMessage.text = res.data.message);
                    }
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = error.response.data.message;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },


        /**
         * Fetches organisation groups from the server.
         * 
         * @param {number} page - The page number to fetch.
         * @param {number} perPage - The number of items per page.
         * @param {object} options - Additional options for the request.
         * @param {string} options.title - The title to filter the groups by.
         * @returns {Promise<Array>} - A promise that resolves to an array of organisation groups.
         */
        async fetchOrganisationGroups(page = 1, perPage = 25, options = { title: "" }) {
            let config = {
                method: "get",
                url: `/organisation/group/index`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    page: page,
                    per_page: perPage,
                    archived: false,
                    title: options.title,
                },
            };

            this.isLoading.orgGroups = true;
            api(config).then((response) => {
                if (page === 1) {
                    this.orgGroups = response.data.data;
                } else {
                    this.orgGroups = this.orgGroups.concat(response.data.data);
                }
                this.hasMore.orgGroups = this.orgGroups.length <  response.data.total;
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.isLoading.orgGroups = false;
            });
        },

        /**
         * Fetches archived organisation groups.
         * 
         * @param {number} page - The page number.
         * @param {number} perPage - The number of items per page.
         * @param {object} options - Additional options.
         * @param {string} options.title - The title of the organisation group.
         * @returns {Promise<void>} - A promise that resolves when the fetch is complete.
         */
        async fetchArchivedOrganisationGroups(page = 1, perPage = 25, options = { title: "" }) {
            let config = {
                method: "get",
                url: `/organisation/group/index`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    page: page,
                    per_page: perPage,
                    archived: true,
                    title: options.title,
                },
            };

            this.isLoading.orgGroupsArchived = true;
            api(config).then((response) => {
                if (page === 1) {
                    this.orgGroupsArchived = response.data.data;
                } else {
                    this.orgGroupsArchived = this.orgGroupsArchived.concat(response.data.data);
                }
                this.hasMore.orgGroupsArchived = this.orgGroupsArchived.length <  response.data.total;
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.isLoading.orgGroupsArchived = false;
            });
        },

        /**
         * Searches for organization groups based on the provided query.
         *
         * @param {string} query - The search query.
         * @param {boolean} archived - Indicates whether to include archived groups in the search.
         * @param {number} page - The page number of the search results.
         * @param {number} perPage - The number of results per page.
         * @returns {Promise<void>} - A promise that resolves when the search is complete.
         */
        async searchOrganisationGroups(query = "", archived = false, page = 1, perPage = 25) {
            let config = {
                method: "GET",
                url: `/organisation/group/index`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    title: query,
                    archived: archived,
                    page: page,
                    per_page: perPage,
                },
            };

            this.isLoading.groupsSearchResults = true;
            return api(config).then((response) => {
                if (page === 1) {
                    this.orgGroupsSearchResults = response.data.data;
                } else {
                    this.orgGroupsSearchResults = this.orgGroupsSearchResults.concat(response.data.data);
                }
                this.hasMore.groupsSearchResults = this.orgGroupsSearchResults.length <  response.data.total;
                return response;
            }).catch((error) => {
                console.error(error);
                return error.response;
            }).finally(() => {
                this.isLoading.groupsSearchResults = false;
            });
        },

        clearSearchResults() {
            this.orgGroupsSearchResults = [];
        },
        
        /**
         * API Call:
         * Fetches a list of organisation groups from the server.
         */
        async getOrgGroups() {
            let config = {
                method: "get",
                url: "/organisation/group/index",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };

            const cachedData = await db.orgGroupsList.toArray();
            if (
                cachedData &&
                cachedData.length > 0 &&
                !this.isUpdated.orgGroups
            ) {
                this.orgGroups = JSON.parse(
                    cachedData.at(0).orgGroupsListDexie
                );
                return;
            }

            try {
                this.isLoading.orgGroups = true;
                this.orgGroups = await api(config).then((response) => {
                    if (!cachedData || cachedData.length === 0) {
                        db.orgGroupsList.add(
                            {
                                orgGroupsListDexie: JSON.stringify(
                                    response.data.reverse()
                                ),
                            },
                            [0]
                        );
                    } else {
                        db.orgGroupsList.update(0, {
                            orgGroupsListDexie: JSON.stringify(
                                response.data.reverse()
                            ),
                        });
                    }
                    this.isLoading.orgGroups = false;
                    this.isUpdated.orgGroups = false;
                    return response.data;
                });
            } catch (error) {
                console.error(error);
                this.isLoading.orgGroups = false;
            }
        },

        async updateOrgGroup(orgGroup) {
            const updateData = {
                id: orgGroup.id,
                title: orgGroup.title,
            };
            let config = {
                method: "post",
                url: "/organisation/group/update",
                headers: {
                    "Content-Type": "application/json",
                },
                data: updateData,
            };

            try {
                await api(config).then(() => {
                    this.isUpdated.orgGroups = true;
                    this.isUpdated.orgGroupsArchived = true;
                    this.fetchOrganisationGroups(1, 25); // fetch first 25 org groups
                    this.fetchArchivedOrganisationGroups(1, 25); // fetch first 25 archived org groups
                    // this.getOrgGroups();
                    // this.getOrgGroupsArchived();
                    this.responseMessage.type = "success",
                    this.responseMessage.text = "Organization Group Updated";
                });
            } catch (error) {
                const errorMessages = Object.values(error.response.data.errors).join(" ");
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed: ${errorMessages}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        /**
         * API Call:
         *  Fetches a list of archived organisation groups from the server.
         */
        async getOrgGroupsArchived() {
            let config = {
                method: "get",
                url: "/organisation/group/archive/index",
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const cachedData = await db.archivedOrgGroupsList.toArray();
            if (
                cachedData &&
                cachedData.length > 0 &&
                !this.isUpdated.orgGroupsArchived
            ) {
                this.orgGroupsArchived = JSON.parse(
                    cachedData.at(0).archivedOrgGroupsListDexie
                );
                return;
            }

            try {
                this.isLoading.orgGroupsArchived = true;
                this.orgGroupsArchived = await api(config).then((response) => {
                    if (!cachedData || cachedData.length === 0) {
                        db.archivedOrgGroupsList.add(
                            {
                                archivedOrgGroupsListDexie: JSON.stringify(
                                    response.data.reverse()
                                ),
                            },
                            [0]
                        );
                    } else {
                        db.archivedOrgGroupsList.update(0, {
                            archivedOrgGroupsListDexie: JSON.stringify(
                                response.data.reverse()
                            ),
                        });
                    }

                    this.isLoading.orgGroupsArchived = false;
                    this.isUpdated.orgGroupsArchived = false;
                    return response.data;
                });
            } catch (error) {
                console.error(error);
                this.isLoading.orgGroupsArchived = false;
            }
        },

        async archiveOrgGroup(orgGroupId) {
            const archiveId = {
                id: orgGroupId,
            };
            let config = {
                method: "post",
                url: "/organisation/group/delete",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: archiveId,
            };
            try {
                await api(config).then((res) => {
                    this.isUpdated.orgGroupsArchived = true;
                    this.isUpdated.orgGroups = true;
                    this.isUpdated.orgData = true;
                    this.orgGroups = [];
                    this.orgGroupsArchived = [];
                    this.fetchOrganisationGroups(1, 25); // fetch first 25 org groups
                    this.fetchArchivedOrganisationGroups(1, 25); // fetch first 25 archived org groups
                    if(res.status == 200){
                        this.responseMessage.type = "success",
                        this.responseMessage.text = "Organization Group Archived";
                    }
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        async unarchiveOrgGroup(orgGroupId) {
            let requestData = {
                id: orgGroupId,
            };
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: "/organisation/group/undelete",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: requestData,
            };
            try {
                await api(config).then((res) => {
                    this.isUpdated.orgGroups = true;
                    this.isUpdated.orgGroupsArchived = true;
                    this.orgGroups = [];
                    this.orgGroupsArchived = [];
                    this.fetchOrganisationGroups();
                    this.fetchArchivedOrganisationGroups();
                    if(res.status == 200){
                        this.responseMessage.type = "success",
                        this.responseMessage.text = "Organization Group set to Active"; 
                    }
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        async getOrgGroupsListOfOrgs(orgGroupId) {
            let requestData = {
                id: orgGroupId,
            };
            let config = {
                method: "post",
                url: "/organisation/group/get",
                headers: {
                    "Content-Type": "application/json",
                },
                data: requestData,
            };
            try {
                this.orgsInlistOfGroups = await api(config).then((response) => {
                    return response.data;
                });
            } catch (error) {
                console.error(error);
            }
        },

        setSelectedOrgGroups(selectedOrgGroups) {
            this.selectedOrgGroups = selectedOrgGroups;
        },

        async updateSelectedCheckboxes(organisationId, organisationGroup) {
            const data = {
                organisation_id: organisationId,
                organisation_group: organisationGroup,
            };
            let config = {
                method: "post",
                url: "/subscription/add",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: data,
            };
            try {
                this.updateSubscriptions = await api(config).then(
                    (response) => {
                        localStorage.setItem(
                            "updateSubscriptions",
                            JSON.stringify(response.data)
                        );
                        this.isUpdated.orgData = true;
                        this.getOrgData();
                        this.responseMessage.type = "success";
                        this.responseMessage.text =
                            "Organization Subscriptions Updated";
                        return response.data;
                    }
                );
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        async updateManageSubscriptions(arr) {
            var ids = arr.map((item) => {
                return item.id;
            });
            this.organisation_group = { organisation: ids };
        },

        // Organisation System Preferences
        async getOrgPreferences(organizationId) {
            try {
                await this.fetchOrgPreferencesFromAPI(organizationId);

                // Check if orgPreferences is null or undefined
                if (this.orgPreferences == null || this.orgModules == null) {
                    // console.log("Create new but got data", this.orgPreferences);
                    this.metadata.event_types = [];
                    this.metadata.industry = [];
                    this.metadata.area_of_law = [];
                    this.metadata.jurisdiction = [];
                    this.metadata.covid19 = [];
                    this.metadata.employment_lifecycle = [];
                    this.metadata.type_of_content = [];
                    this.metadata.content_type = [];
                    this.metadata.organisation_type = [];
                    this.metadata.proprietary = [];

                    this.preferenceMetadata.event_types = [];
                    this.preferenceMetadata.industry = [];
                    this.preferenceMetadata.area_of_law = [];
                    this.preferenceMetadata.jurisdiction = [];
                    this.preferenceMetadata.covid19 = [];
                    this.preferenceMetadata.employment_lifecycle = [];
                    this.preferenceMetadata.type_of_content = [];
                    this.preferenceMetadata.content_type = [];
                    this.preferenceMetadata.organisation_type = [];
                    this.preferenceMetadata.proprietary = [];

                    await this.createOrgPreferences({ organizationId });
                    // await this.getOrgPreferences(organizationId);
                    return;
                }
                // Update the state properties
                // this.metadata = this.orgPreferences;
                // this.preferenceMetadata = this.orgEmailPreferences;

                this.metadata.event_types = JSON.parse(
                    this.orgPreferences.event_types || "[]"
                );
                this.metadata.industry = JSON.parse(
                    this.orgPreferences.industry || "[]"
                );
                this.metadata.area_of_law = JSON.parse(
                    this.orgPreferences.area_of_law || "[]"
                );
                this.metadata.jurisdiction = JSON.parse(
                    this.orgPreferences.jurisdiction || "[]"
                );
                this.metadata.covid19 = JSON.parse(
                    this.orgPreferences.covid19 || "[]"
                );
                this.metadata.employment_lifecycle = JSON.parse(
                    this.orgPreferences.employment_lifecycle || "[]"
                );
                this.metadata.type_of_content = JSON.parse(
                    this.orgPreferences.type_of_content || "[]"
                );
                this.metadata.content_type = JSON.parse(
                    this.orgPreferences.content_type || "[]"
                );
                this.metadata.organisation_type = JSON.parse(
                    this.orgPreferences.organisation_type || "[]"
                );
                this.metadata.proprietary = JSON.parse(
                    this.orgPreferences.proprietary || "[]"
                );

                this.preferenceMetadata.event_types = JSON.parse(
                    this.orgEmailPreferences.event_types || "[]"
                );
                this.preferenceMetadata.content_type = JSON.parse(
                    this.orgEmailPreferences.content_type || "[]"
                );
                this.preferenceMetadata.industry = JSON.parse(
                    this.orgEmailPreferences.industry || "[]"
                );
                this.preferenceMetadata.area_of_law = JSON.parse(
                    this.orgEmailPreferences.area_of_law || "[]"
                );
                this.preferenceMetadata.jurisdiction = JSON.parse(
                    this.orgEmailPreferences.jurisdiction || "[]"
                );
                this.preferenceMetadata.covid19 = JSON.parse(
                    this.orgEmailPreferences.covid19 || "[]"
                );
                this.preferenceMetadata.employment_lifecycle = JSON.parse(
                    this.orgEmailPreferences.employment_lifecycle || "[]"
                );
                this.preferenceMetadata.organisation_type = JSON.parse(
                    this.orgEmailPreferences.organisation_type || "[]"
                );
                this.preferenceMetadata.type_of_content = JSON.parse(
                    this.orgEmailPreferences.type_of_content || "[]"
                );
                this.preferenceMetadata.proprietary = JSON.parse(
                    this.orgEmailPreferences.proprietary || "[]"
                );

                this.selectedModules = this.orgModules.filter(
                    (module) => module.pivot.preference === 1
                );
                this.selectedLanguage = this.orgPreferences?.language;
                this.selectedEmailPref = this.orgEmailPreferences?.frequency;
            } catch (error) {
                console.error(error);
            }
        },

        async fetchOrgPreferencesFromAPI(organizationId) {
            let config = {
                method: "get",
                url: `/organisation/preference/${organizationId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            try {
                this.isLoading.orgPreferences = true;
                const response = await api(config);
                this.orgPreferences = response.data.organ_preference;

                this.orgEmailPreferences = response.data.organ_metadata;
                this.orgModules = response.data.organ_module;

                // Set selected modules
                this.setSelectedModules(
                    this.orgModules.filter(
                        (module) => module.pivot.preference === 1
                    )
                );
                this.isLoading.orgPreferences = false;
            } catch (error) {
                console.error(error);
                this.isLoading.orgPreferences = false;
            }
        },

        setSelectedLanguage(selectedLanguage) {
            this.selectedLanguage = selectedLanguage;
        },

        setSelectedEmailPref(selectedEmailPref) {
            this.selectedEmailPref = selectedEmailPref;
        },

        async updateOrgPreferences(organizationId) {
            const data = {
                organisation_id: organizationId,
                language: {
                    language: this?.selectedLanguage,
                    metadata: {
                        event_types: this.metadata?.event_types,
                        content_type: this.metadata?.content_type,
                        industry: this.metadata?.industry,
                        jurisdiction: this.metadata?.jurisdiction,
                        organisation_type: this.metadata?.organisation_type,
                        covid19: this.metadata?.covid19,
                        area_of_law: this.metadata?.area_of_law,
                        employment_lifecycle:
                            this.metadata?.employment_lifecycle,
                        type_of_content: this.metadata?.type_of_content,
                        proprietary: this.metadata?.proprietary,
                    },
                },
                preference: {
                    frequency: this?.selectedEmailPref,
                    metadata: {
                        event_types: this.preferenceMetadata?.event_types,
                        content_type: this.preferenceMetadata?.content_type,
                        industry: this.preferenceMetadata?.industry,
                        jurisdiction: this.preferenceMetadata?.jurisdiction,
                        organisation_type:
                            this.preferenceMetadata?.organisation_type,
                        covid19: this.preferenceMetadata?.covid19,
                        area_of_law: this.preferenceMetadata?.area_of_law,
                        employment_lifecycle:
                            this.preferenceMetadata?.employment_lifecycle,
                        type_of_content:
                            this.preferenceMetadata?.type_of_content,
                        proprietary: this.preferenceMetadata?.proprietary,
                    },
                },
                module: {
                    module_id: this.module?.module_id,
                    preference: this.module?.preference,
                },
            };
            let config = {
                method: "post",
                url: `/organisation/preference/update`,
                headers: {
                    "Content-Type": "application/json",
                },
                data,
            };

            try {
                return await api(config).then((res) => {
                    this.getOrgPreferences(organizationId);
                    if (res.status == 200) {
                        (this.responseMessage.type = "success"),
                            (this.responseMessage.text =
                                "Organization Preferences Updated");
                    }
                });
                // localStorage.setItem(`orgPreferences-${organizationId}`, JSON.stringify(response.data.organ_preference));
            } catch (error) {
                // Handle the error here
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        async updateOrgModules(organizationId) {
            const existingData = {
                organisation_id: organizationId,
                language: {
                    language: this?.selectedLanguage,
                    metadata: {
                        event_types: this.metadata?.event_types,
                        content_type: this.metadata?.content_type,
                        industry: this.metadata?.industry,
                        jurisdiction: this.metadata?.jurisdiction,
                        organisation_type: this.metadata?.organisation_type,
                        covid19: this.metadata?.covid19,
                        area_of_law: this.metadata?.area_of_law,
                        employment_lifecycle:
                            this.metadata?.employment_lifecycle,
                        type_of_content: this.metadata?.type_of_content,
                        proprietary: this.metadata?.proprietary,
                    },
                },
                preference: {
                    frequency: this?.selectedEmailPref,
                    metadata: {
                        event_types: this.preferenceMetadata?.event_types,
                        content_type: this.preferenceMetadata?.content_type,
                        industry: this.preferenceMetadata?.industry,
                        jurisdiction: this.preferenceMetadata?.jurisdiction,
                        organisation_type:
                            this.preferenceMetadata?.organisation_type,
                        covid19: this.preferenceMetadata?.covid19,
                        area_of_law: this.preferenceMetadata?.area_of_law,
                        employment_lifecycle:
                            this.preferenceMetadata?.employment_lifecycle,
                        type_of_content:
                            this.preferenceMetadata?.type_of_content,
                        proprietary: this.preferenceMetadata?.proprietary,
                    },
                },
                module: {
                    module_id: this.module?.module_id,
                    preference: this.module?.preference,
                },
            };

            // Create a copy of existingData and update only the module related data
            const updatedData = {
                ...existingData,
                module: {
                    module_id: this.module?.module_id,
                    preference: this.module?.preference,
                },
            };

            let config = {
                method: "post",
                url: `/organisation/preference/update`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: updatedData,
            };

            try {
                const response = await api(config);
                // Handle the response here
                // Update the data in the local storage
                // localStorage.setItem(`orgPreferences-${organizationId}`, JSON.stringify(response.data.organ_preference));
            } catch (error) {
                // Handle the error here
                console.error(error);
            }
        },

        async createOrg(data) {
            const config = {
                method: "post",
                url: `/organisation/create`,
                headers: {
                    "Content-Type": "application/json",
                },
                data,
            };

            try {
                return await api(config).then(() => {
                    this.isUpdated.orgData = true;
                    this.getOrgData();
                    this.responseMessage.type = "success";
                    this.responseMessage.text = "Organization created successfully";
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed: ${error.response.data.errors.name}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        /**
         * Creates an organization group.
         * @param {Object} data - The data for creating the organization group.
         * @returns {Promise<void>} - A promise that resolves when the organization group is created.
         * @throws {Error} - If the request fails.
         */
        async createOrgGroup(data) {
            const config = {
                method: "post",
                url: "/organisation/group/create",
                headers: {
                    "Content-Type": "application/json",
                },
                data,
            };

            try {
                return await api(config).then(() => {
                    this.isUpdated.orgGroups = true;
                    this.orgGroups = [];
                    this.fetchOrganisationGroups();
                    this.responseMessage.type = "success";
                    this.responseMessage.text = "Organization Group created successfully";
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed: ${error.response.data.errors.title}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
            }
        },

        async createOrgPreferences({ organizationId }) {
            const data = {
                organisation_id: organizationId,
                language: {
                    language: this?.selectedLanguage,
                    metadata: {
                        event_types: [],
                        content_type: [],
                        industry: [],
                        jurisdiction: [],
                        organisation_type: [],
                        covid19: [],
                        area_of_law: [],
                        employment_lifecycle: [],
                        type_of_content: [],
                        proprietary: [],
                        // event_types: this.metadata?.event_types,
                        // content_type: this.metadata?.content_type,
                        // industry: this.metadata?.industry,
                        // jurisdiction: this.metadata?.jurisdiction,
                        // organisation_type: this.metadata?.organisation_type,
                        // covid19: this.metadata?.covid19,
                        // area_of_law: this.metadata?.area_of_law,
                        // employment_lifecycle:
                        //     this.metadata?.employment_lifecycle,
                        // type_of_content: this.metadata?.type_of_content,
                        // proprietary: this.metadata?.proprietary,
                    },
                },
                preference: {
                    frequency: this?.selectedEmailPref,
                    metadata: {
                        event_types: [],
                        content_type: [],
                        industry: [],
                        jurisdiction: [],
                        organisation_type: [],
                        covid19: [],
                        area_of_law: [],
                        employment_lifecycle: [],
                        type_of_content: [],
                        proprietary: [],
                        // event_types: this.preferenceMetadata?.event_types,
                        // content_type: this.preferenceMetadata?.content_type,
                        // industry: this.preferenceMetadata?.industry,
                        // jurisdiction: this.preferenceMetadata?.jurisdiction,
                        // organisation_type:
                        //     this.preferenceMetadata?.organisation_type,
                        // covid19: this.preferenceMetadata?.covid19,
                        // area_of_law: this.preferenceMetadata?.area_of_law,
                        // employment_lifecycle:
                        //     this.preferenceMetadata?.employment_lifecycle,
                        // type_of_content:
                        //     this.preferenceMetadata?.type_of_content,
                        // proprietary: this.preferenceMetadata?.proprietary,
                    },
                },
                module: {
                    module_id: this.module?.module_id,
                    preference: this.module?.preference,
                },
            };

            let config = {
                method: "post",
                url: `/organisation/preference/add`,
                headers: {
                    "Content-Type": "application/json",
                },
                data,
            };

            try {
                const response = await api(config);
                // Handle the response here
                // Update the data in the local storage
                // localStorage.setItem(`orgPreferences-${organizationId}`, JSON.stringify(response.data.organ_preference));
            } catch (error) {
                // Handle the error here
                console.error(error);
            }
        },

        formatIndustryData(industryData) {
            return industryData.map((industryName) => {
                const subIndustries =
                    this.getSubIndustriesByIndustry(industryName);
                return { [industryName]: subIndustries };
            });
        },

        // A helper function to get the sub-industries by the industry name
        getSubIndustriesByIndustry(industryName) {
            const industry = this.industry.find(
                (industry) => industry.name === industryName
            );
            return industry ? JSON.parse(industry.json) : [];
        },

        async getModules(organizationId) {
            try {
                const cachedModules = localStorage.getItem("modules");
                if (cachedModules) {
                    // If the data is found in localStorage, use it
                    this.modules = JSON.parse(cachedModules);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: `/organisation/module/${organizationId}`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.modules = response.data;
                    localStorage.setItem(
                        "modules",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        setSelectedModules(selectedModules) {
            this.selectedModules = selectedModules;
        },

        async getEventTypes() {
            try {
                const cachedEventTypes = localStorage.getItem("eventTypes");
                if (cachedEventTypes) {
                    // If the data is found in localStorage, use it
                    this.eventTypes = JSON.parse(cachedEventTypes);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/event/type/index",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    };
                    const response = await api(config);
                    this.eventTypes = response.data;
                    localStorage.setItem(
                        "eventTypes",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getIndustry() {
            try {
                const cachedIndustry = localStorage.getItem("industries");
                if (cachedIndustry) {
                    // If the data is found in localStorage, use it
                    this.industries = JSON.parse(cachedIndustry);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/industry/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.industries = response.data;
                    localStorage.setItem(
                        "industries",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getAreasOfLaws() {
            try {
                const cachedAreasOfLaws = localStorage.getItem("areasOfLaws");
                if (cachedAreasOfLaws) {
                    // If the data is found in localStorage, use it
                    this.areasOfLaws = JSON.parse(cachedAreasOfLaws);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/areaof/law/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.areasOfLaws = response.data;
                    localStorage.setItem(
                        "areasOfLaws",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getJurisdictions() {
            try {
                const cachedJurisdictions =
                    localStorage.getItem("jurisdictions");
                if (cachedJurisdictions) {
                    // If the data is found in localStorage, use it
                    this.jurisdictions = JSON.parse(cachedJurisdictions);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/jurisdiction/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.jurisdictions = response.data;
                    localStorage.setItem(
                        "jurisdictions",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getCovidNineteen() {
            try {
                const cachedCovidNineteen =
                    localStorage.getItem("covidNineteen");
                if (cachedCovidNineteen) {
                    // If the data is found in localStorage, use it
                    this.covidNineteen = JSON.parse(cachedCovidNineteen);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/covid19/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.covidNineteen = response.data;
                    localStorage.setItem(
                        "covidNineteen",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getEmploymentLifecycles() {
            try {
                const cachedEmploymentLifecycles = localStorage.getItem(
                    "employmentLifecycles"
                );
                if (cachedEmploymentLifecycles) {
                    // If the data is found in localStorage, use it
                    this.employmentLifecycles = JSON.parse(
                        cachedEmploymentLifecycles
                    );
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/employment/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.employmentLifecycles = response.data;
                    localStorage.setItem(
                        "employmentLifecycles",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getTypeOfContents() {
            try {
                const cachedTypeOfContents =
                    localStorage.getItem("typeOfContents");
                if (cachedTypeOfContents) {
                    // If the data is found in localStorage, use it
                    this.typeOfContents = JSON.parse(cachedTypeOfContents);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/type/content/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.typeOfContents = response.data;
                    localStorage.setItem(
                        "typeOfContents",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getContentTypes() {
            try {
                const cachedContentTypes = localStorage.getItem("contentTypes");
                if (cachedContentTypes) {
                    // If the data is found in localStorage, use it
                    this.contentTypes = JSON.parse(cachedContentTypes);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/content/type/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.contentTypes = response.data;
                    localStorage.setItem(
                        "contentTypes",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getOrganisationTypes() {
            try {
                let config = {
                    method: "get",
                    url: "/organisation/type/index",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const response = await api(config);
                this.organisationTypes = response.data;
                localStorage.setItem(
                    "organisationTypes",
                    JSON.stringify(response.data)
            );
            } catch (error) {
                console.error(error);
            }
        },

        async getProprietary() {
            try {
                const cachedProprietary = localStorage.getItem("proprietaries");
                if (cachedProprietary) {
                    // If the data is found in localStorage, use it
                    this.proprietaries = JSON.parse(cachedProprietary);
                } else {
                    // If the data is not found in localStorage, make the API call
                    let config = {
                        method: "get",
                        url: "/proprietary/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await api(config);
                    this.proprietaries = response.data;
                    localStorage.setItem(
                        "proprietaries",
                        JSON.stringify(response.data)
                    );
                }
            } catch (error) {
                console.error(error);
            }
        },

        async getFranchises() {
            try {    
                let config = {
                    method: "get",
                    url: "/franchise/index",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const response = await api(config);
                this.franchises = response.data;
                localStorage.setItem(
                    "franchises",
                    JSON.stringify(response.data)
                );
            } catch (error) {
                console.error(error);
            }
        },

        setSelectedOrgs(item, index) {
            let existingOrg = null;
            let data = {
                name: item,
                index: index,
            };

            existingOrg = this.selectedOrgs.find((org) => {
                return org.name == item || null;
            });

            this.existingOrgs = existingOrg;

            if (this.existingOrgs == null) {
                this.selectedOrgs.push(data);
            } else {
                this.selectedOrgs = this.selectedOrgs.filter(
                    (o) => o.name !== item
                );
            }
            return this.selectedOrgs;
        },
        getCurrentOrgAssociatedGroups() {
            if (this.currentOrgDetails) {
                return this.currentOrgDetails.organisation_group || [];
            }
            return [];
        },
    },
});
