import { createApp, errorCaptured } from "vue";
import store from "./store";
import router from "./router";
import { createPinia } from "pinia";
import Loading from "vue3-loading-screen";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VTooltip from "v-tooltip";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import PopupMessage from "./components/notifications/PopupMessage";

//axios.defaults.baseURL = location.hostname == 'localhost' ? 'http://localhost:8000/api' : 'https://stage.strima.tv/api'
//axios.defaults.withCredentials = true;

// Retrieve token and expiration from local storage
const token = localStorage.getItem("token");
const expirationTime = localStorage.getItem("tokenExpiration");
console.log("exp:",expirationTime);
// Check if token is expired
if (token && expirationTime && new Date(expirationTime) < new Date()) {
    // Redirect to the login page
    localStorage.clear();
    console.log("Token expired");
}

const app = createApp({});

require("./fontawesome");

app.use(Loading, {
    bg: "#313E47",
    slot: `
      <div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    `,
});
app.use(store);
app.use(VTooltip);
app.use(CKEditor);
app.use(router);
const pinia = createPinia();
app.use(pinia);
app.component("VueDatePicker", VueDatePicker);

/* app.mixin({
    onErrorCaptured(err, vm, info) {
        const errorMessage = `Captured in component: ${
            vm.$options.name
        }\nError: ${err.toString()}\nInfo: ${info}`;
        console.log(
            `Captured in component: ${
                vm.$options.name
            }\nError: ${err.toString()}\nInfo: ${info}`
        );
        const popupComponent = app.use(PopupMessage, {
            color: "error",
            message: errorMessage,
        });
        //setInterval
        setTimeout(() => {
            popupComponent.$el.remove();
        }, 5000);
        return false; // prevents the error from propagating further[^1^][1][^2^][2]Ô
    },
}); */
/* app.mixin({
    methods: {
        displayPopupMessage(errorMessage) {
            const popupComponent = app.mount(PopupMessage, {
                color: 'error',
                message: errorMessage,
            });

            const teleportTarget = document.body;
            if (teleportTarget) {
                popupComponent.$teleport(teleportTarget);
            }

            setTimeout(() => {
                app.unmount(popupComponent);
            }, 5000);
        },
    },
    onErrorCaptured(err, vm, info) {
        const errorMessage = `Captured in component: ${
            vm.$options.name
        }\nError: ${err.toString()}\nInfo: ${info}`;
        console.log(errorMessage);

        this.displayPopupMessage(errorMessage);

        return false; // prevents the error from propagating further
    },
});
 */
app.mount("#app");
