<template>
    <div class="modal">
        <Transition>
            <PopupMessage
                v-if="popupVisible"
                :color="popupMessage.color"
                :message="popupMessage.message"
            />
        </Transition>
        <div class="modal-card">
            <div class="request-demo-form">
                <div class="header">
                    <h2>Posh Support</h2>
                    <div class="close-button" @click="$emit('close'); resetForm()">
                        <img src="/assets/icons/close-modal.svg" alt="" />
                    </div>
                </div>
                <div class="form-title">
                    <!-- <h3 class="active">Posh Support</h3> -->
                    <!-- <div class="seperator"></div> -->
                    <!-- <h3>Request Assistance</h3> -->

                </div>
                <div class="legend">
                    <div class="required-dot"></div>
                    <span>Required fields</span>
                </div>
                <form @submit.prevent>
                    <div class="main-details">
                        <div
                            class="input-container required"
                        >
                            <input
                            v-model.trim="first_name"
                            type="text"
                                placeholder="First Name"
                                @change="validateName('firstName')"
                                @keydown.tab="validateName('firstName')"
                            />
                            <div v-if="nameError" class="error-text">
                                {{ nameError }}
                            </div>
                        </div>
                        <div class="input-container required">
                            <input
                                v-model.trim="last_name"
                                type="text"
                                placeholder="Last Name"
                                @change="validateName('lastName')"
                                @keydown.tab="validateName('lastName')"
                            />
                            <div v-if="surnameError" class="error-text">
                                {{ surnameError }}
                            </div>
                        </div>
                        <div class="input-container">
                            <input
                                v-model="job_title"
                                type="text"
                                placeholder="Job Title"
                            />
                            <div v-if="jobError" class="error-text">
                                {{ jobError }}
                            </div>
                        </div>
                        <div class="input-container">
                            <input
                                v-model="entity"
                                type="text"
                                placeholder="Legal Entity Name with DBA"
                            />
                            <div v-if="entityError" class="error-text">
                                {{ entityError }}
                            </div>
                        </div>
                        <div class="input-container required">
                            <input
                                v-model="email"
                                type="email"
                                placeholder="Email Address"
                                @change="validateEmail('email')"
                            />
                            <div v-if="emailError" class="error-text">
                                {{ emailError }}
                            </div>
                        </div>
                        <div class="input-container required">
                            <input
                                v-model="confirm_email"
                                type="email"
                                placeholder="Confirm Email Address"
                                @change="validateEmail('confirm email')"
                            />
                            <div v-if="confirmEmailError" class="error-text">
                                {{ confirmEmailError }}
                            </div>
                        </div>
                        <div class="input-container">
                            <input
                                v-model="phone"
                                type="tel"
                                placeholder="Phone Number"
                                @change="validatePhoneNumber"
                            />
                            <div v-if="phoneError" class="error-text">
                                {{ phoneError }}
                            </div>
                        </div>
                        <!-- <div class="input-container required">
                            <div class="select-container">
                                <select
                                    id="support-preferred-contact"
                                    v-model="contact_by"
                                    @change="checkIfSelectEmpty"
                                >
                                    <option value="" disabled>
                                        Preferred Contact Method
                                    </option>
                                    <option value="email">Email</option>
                                    <option value="phone">Phone</option>
                                </select>
                                <div class="select-arrow"></div>
                            </div>
                            <div v-if="contactError" class="error-text">
                                {{ contactError }}
                            </div>
                        </div> -->
                    </div>
                    <div class="client-question">
                        <div id="radio-title" class="title required">How can we help you?</div>
                        <div class="client-question-container">
                            <div class="input-container">
                                <input
                                    id="1"
                                    v-model="help"
                                    type="radio"
                                    value="Technical issue with the site."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="1"
                                    >Technical issue with the site.</label
                                >
                            </div>
                            <div class="input-container">
                                <input
                                    id="2"
                                    v-model="help"
                                    type="radio"
                                    value="I have a question about a document/form."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="2"
                                    >I have a question about a
                                    document/form.</label
                                >
                            </div>
                            <div class="input-container">
                                <input
                                    id="3"
                                    v-model="help"
                                    type="radio"
                                    value="I forgot my username/password."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="3"
                                    >I forgot my username/password.</label
                                >
                            </div>
                            <div class="input-container">
                                <input
                                    id="4"
                                    v-model="help"
                                    type="radio"
                                    value="Purchase additional subscription content."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="4"
                                    >Purchase additional subscription
                                    content.</label
                                >
                            </div>
                            <div class="input-container">
                                <input
                                    id="5"
                                    v-model="help"
                                    type="radio"
                                    value="Update/add a user to my subscription."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="5"
                                    >Update/add a user to my
                                    subscription.</label
                                >
                            </div>
                            <div class="input-container">
                                <input
                                    id="6"
                                    v-model="help"
                                    type="radio"
                                    value="Learn more about Polinelli’s legal services."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="6"
                                    >Learn more about Polinelli’s legal
                                    services.</label
                                >
                            </div>
                            <div class="input-container">
                                <input
                                    id="7"
                                    v-model="help"
                                    type="radio"
                                    value="Other (specify below)."
                                    @change="checkIfRadioEmpty"
                                />
                                <label for="7">Other (specify below).</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="helpError" class="error-text">
                        {{ helpError }}
                    </div>
                    <div class="input-container additional-information">
                        <textarea
                            id="support-additional-information"
                            v-model="desc"
                            rows="5"
                            placeholder="Additional Information"
                        ></textarea>
                    </div>

                    <div id="support-terms-checkbox" class="terms-check required">
                        <input
                            id="support-terms"
                            v-model="policy"
                            type="checkbox"
                            value="1"
                            @change="checkIfCheckboxEmpty"
                        />
                        <label for="support-terms">
                            By submitting this form, I confirm my consent to
                            processing and accept the terms of
                            <RouterLink to="/privacy-policy">
                                <a href="#" target="_blank">Polsinelli’s Privacy Policy</a>.
                            </RouterLink>
                        </label
                        >
                    </div>
                    <div v-if="policyError" class="error-text">
                        {{ policyError }}
                    </div>
                    <div class="button-container">
                        <button
                            role="button"
                            tabindex="0"
                            :disabled="!requiredDataFilled() || submittingForm"
                            class="btn secondary-btn"
                            @click="submitRequestAsst"
                        >
                            {{ submitText }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useResetStore } from "../../store/resetStore";
import PopupMessage from "../../components/notifications/PopupMessage.vue";
import { useIsValidEmail, isValidPhoneNumber, useIsValidName } from "../../composables/inputValidators";
import { storeToRefs } from "pinia";


const emits = defineEmits(["close"]);

const ResetStore = useResetStore();
const { responseMessage } = storeToRefs(ResetStore);
const popupVisible = ref(false);
const popupBgColor = ref("");

const first_name = ref("");
const last_name = ref("");
const email = ref(null);
const confirm_email = ref(null);
const phone = ref("");
const help = ref("");
const job_title = ref("");
const contact_by = ref("email");
const entity = ref("");
const desc = ref("");
const policy = ref(false);
const submitText = ref("Submit");

const nameError = ref(null);
const surnameError = ref(null);
const emailError = ref(null);
const jobError = ref(null);
const confirmEmailError = ref(null);
const phoneError = ref(null);
const contactError = ref(null);
const entityError = ref(null);
const policyError = ref(null);
const helpError = ref(null);
// const status = ref("");
const submittingForm = ref(false);

const popupMessage = ref({
    message: "",
    color: "",
});

/**
 * Checks if all the required fields have been filled in.
 */
const requiredDataFilled = () => {
    return (
        first_name.value &&
        last_name.value &&
        email.value &&
        confirm_email.value &&
        //phone.value &&
        // contact_by.value &&
        help.value &&
        policy.value
    )
}

const validateName = (field) => {
    if (field === 'firstName') {
        if (!first_name.value) {
            nameError.value = 'First name is required.'
        } else if (!useIsValidName(first_name.value)) {
            nameError.value = 'First name may only contain letters, spaces, apostrophes, and hyphens.'
        } else {
            nameError.value = '';
        }
    }

    if (field === 'lastName') {
        if (!last_name.value) {
            surnameError.value = 'Last name is required.'
        } else if (!useIsValidName(last_name.value)) {
            surnameError.value = 'Last name may only contain letters, spaces, apostrophes, and hyphens.'
        } else {
            surnameError.value = '';
        }
    }
}


 /**
 * Checks whether the given input string is valid email addres.
 * @param {String} field input string to validate
 */
const validateEmail = (field) => {
    if (field === 'email') {
        const emailLowerCase = email.value.toLowerCase();
        if (useIsValidEmail(emailLowerCase)) {
            emailError.value = '';
        } else {
            emailError.value = 'Invalid email address.'
        }
        return;
    }

    if (field === 'confirm email') {
        const emailLowerCase = confirm_email.value.toLowerCase();
        if (useIsValidEmail(emailLowerCase)) {
            confirmEmailError.value = '';
        } else {
            confirmEmailError.value = 'Invalid email address.'
        }
        return;
    }
}

const validatePhoneNumber = () => {
    if (!isValidPhoneNumber(phone.value)) {
        phoneError.value = 'Phone number must be 10 digits long with no spaces in between.';
    } else {
        phoneError.value = '';
    }
}

const hasErrorMessage = () => {
    return (
        nameError.value ||
        surnameError.value ||
        emailError.value ||
        jobError.value ||
        confirmEmailError.value ||
        phoneError.value ||
        contactError.value ||
        entityError.value ||
        policyError.value ||
        helpError.value
    );
}

const submitRequestAsst = () => {
    validateName('firstName');
    validateName('lastName');
    validateEmail('email');
    validateEmail('confirm email');
    if (phone.value.length > 0) {
        validatePhoneNumber();
    }

    if (!policy.value) {
        policyError.value = 'Please accept the terms of Polsinelli’s Privacy Policy'
    } else {
        policyError.value = null
    }

    if (!hasErrorMessage() && requiredDataFilled() && !submittingForm.value) {
        submitText.value = "Submitting..."
        const data = {
            first_name: first_name.value,
            last_name: last_name.value,
            job_title: job_title.value,
            entity: entity.value,
            email: email.value,
            phone: phone.value,
            contact_by: contact_by.value,
            description: desc.value,
            help: help.value,
            policy: policy.value,
        };

        submittingForm.value = true;
        ResetStore.submitRequestAsst(data).then((res) => {
            popupMessage.value.color = responseMessage.value.type;
            popupMessage.value.message = responseMessage.value.text;
            popupVisible.value = true;
            submittingForm.value = false;
            setTimeout(() => {
                popupVisible.value = false;
                popupMessage.value.color = "";
                popupMessage.value.message = "";
                submitText.value = "Submit";
                resetForm();
                emits('close');
            }, 5000);
        });
    }
}

const resetForm = () => {
    first_name.value = "";
    last_name.value = ""
    job_title.value = ""
    entity.value = ""
    email.value = ""
    confirm_email.value = ""
    phone.value = ""
    help.value = ""
    contact_by.value = "email"
    desc.value = ""
    policy.value = false
    popupVisible.value = false;
    nameError.value = false;
    surnameError.value = false;
    emailError.value = false;
    jobError.value = false;
    confirmEmailError.value = false;
    phoneError.value = false;
    contactError.value = false;
    entityError.value = false;
    policyError.value = false;
    helpError.value = false;
}
</script>

<style lang="scss" scoped>
div.error-text {

    font-size: 0.7rem;
    color: #9f3037;
    margin-bottom: 5px;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
    z-index: 100;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 85px;
    margin-bottom: 100px;
    padding: 0 20px 100px;
  }

  .modal-card {
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 100%;
    overflow-y: auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
  }

  .close-button {
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 5px;
    background: #313e47;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        filter: invert(1);
        max-width: 100%;
        height: auto;
    }
  }

  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    h3 {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.5;
        margin: 0;
        color: #cbcbcb;

        &.active {
            color: #28323a;
            font-weight: 600;
        }
    }

    .seperator {
        width: 1px;
        height: 1.5rem;
        background: #cbcbcb;
        margin: 0 1rem;
    }
  }

  .legend {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    .required-dot {
        width: 6px;
        height: 6px;
        background: #9f3037;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
    }

    span {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #9f3037;
    }
  }

  .main-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .input-container {
    position: relative;
    &.required::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: #9f3037;
        border-radius: 50%;
        top: -9px;
        right: 0;
    }
  }

  .input-container.not-empty.required::after {
    background: #008000;
  }

  .client-question {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .title {
        font-size: 0.875rem;
        font-weight: 600;
        color: #28323a;
        position: relative;
        display: inline-block;
        width: max-content;
        margin-bottom: 0.2rem;

        &.required::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: #9f3037;
            border-radius: 50%;
            top: -2px;
            right: -8px;
        }
    }

    #radio-title.not-empty::after {
    background: #008000;
  }

    .client-question-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2px 1rem;
    }

    .input-container {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        gap: 2px 10px;

        input[type="radio"] {
            height: 1rem;
            width: 1rem;
        }

        label {
            margin: 0;
        }
    }
  }

  .additional-information {
    margin-bottom: 1rem;
  }

  .marketing-source {
    margin-bottom: 1rem;
  }

  .terms-check {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 1rem;

    input[type="checkbox"] {
        height: 22px;
        width: 22px;
        font-size: 1rem;
        padding: 0px;
    }
    &.required::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: #9f3037;
        border-radius: 50%;
        top: -2px;
        right: 0;
    }
  }

  .terms-check.not-empty.required::after {
    background: #008000;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
}

.select-container {
    position: relative;
}

.select-container select {
    -webkit-appearance: none;
    background-color: #fff;
}

.select-arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    pointer-events: none;
    z-index: 1;
    background: url("/assets/icons/chevron-down.svg") no-repeat center center/contain;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

@media (max-width: 768px) {

    .modal-card {
        padding: 10px;
    }
    h2 {
        font-size: 1.25rem;
    }
    h3 {
        font-size: 1rem;
    }
    .main-details {
        grid-template-columns: 1fr;
    }
    .client-question,
    .terms-check,
    .form-title,
    .legend,
    .marketing-source,
    .additional-information {
        margin-bottom: 0.5rem;
    }
    .client-question {
        .client-question-container {
            grid-template-columns: 1fr;
            gap: 10px 1rem;
        }
    }
  }
  </style>
